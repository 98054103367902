import React, { forwardRef } from "react"
import PropTypes from "prop-types"
import { renderClasses } from "utils/renderClasses"
import { BaseTypeProps } from "../props"
import "./heading6.scss"

const Heading6 = forwardRef(
  ({ children, className, italic = false, semiBold = false }, ref) => (
    <h6
      className={renderClasses("g-heading-tag g-heading6", [
        [className, className],
        [italic, "-i"],
        [semiBold, "-s"],
      ])}
      ref={ref}
    >
      {children}
    </h6>
  )
)

Heading6.props = {
  ...BaseTypeProps,
  semiBold: PropTypes.bool,
}

Heading6.displayName = "Heading6"

Heading6.propTypes = Heading6.props

export default Heading6
