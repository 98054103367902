import React from "react"
import Base, { BaseProps } from "../../base"

const SensoryMotor = ({ fill, stroke, ...props }) => (
  <Base
    {...props}
    className="g-sensoryMotor"
    fill="none"
    height="172"
    viewBox="0 0 172 172"
    width="172"
  >
    <circle cx="86" cy="86" fill={fill} r="86" />
    <circle cx="86" cy="86" fill={fill} r="86" />
    <path
      d="M61.0078 142.924C64.4416 132.473 74.1458 124.859 85.7909 124.859C97.436 124.859 107.289 132.473 110.574 142.924"
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M85.7927 124.71C91.1674 124.71 95.6462 120.38 95.6462 114.856C95.6462 109.482 91.3167 105.003 85.7927 105.003C80.4181 105.003 75.9392 109.333 75.9392 114.856C75.9392 120.38 80.4181 124.71 85.7927 124.71Z"
      fill={stroke}
    />
    <mask fill="white" id="path-5-inside-1_1016_8606">
      <path d="M109.82 142.476L109.074 143.223C109.373 143.073 109.671 142.924 109.97 142.775C109.82 142.625 109.82 142.476 109.82 142.476Z" />
    </mask>
    <path
      d="M109.82 142.476L109.074 143.223C109.373 143.073 109.671 142.924 109.97 142.775C109.82 142.625 109.82 142.476 109.82 142.476Z"
      fill={fill}
    />
    <path
      d="M109.82 142.476L110.82 142.476L110.82 140.062L109.113 141.769L109.82 142.476ZM109.074 143.223L108.367 142.515L109.521 144.117L109.074 143.223ZM109.97 142.775L110.417 143.669L111.658 143.049L110.677 142.068L109.97 142.775ZM109.113 141.769L108.367 142.515L109.781 143.93L110.528 143.183L109.113 141.769ZM109.521 144.117C109.67 144.042 109.82 143.968 109.969 143.893C110.118 143.818 110.268 143.744 110.417 143.669L109.523 141.88C109.373 141.955 109.224 142.03 109.075 142.104C108.925 142.179 108.776 142.253 108.627 142.328L109.521 144.117ZM110.677 142.068C110.724 142.115 110.763 142.179 110.788 142.253C110.8 142.288 110.808 142.324 110.813 142.36C110.815 142.378 110.817 142.397 110.819 142.416C110.819 142.426 110.82 142.436 110.82 142.446C110.82 142.451 110.82 142.456 110.82 142.461C110.82 142.463 110.82 142.466 110.82 142.468C110.82 142.47 110.82 142.471 110.82 142.472C110.82 142.473 110.82 142.473 110.82 142.474C110.82 142.474 110.82 142.475 110.82 142.475C110.82 142.476 110.82 142.476 109.82 142.476C108.82 142.476 108.82 142.477 108.82 142.477C108.82 142.477 108.82 142.478 108.82 142.478C108.82 142.479 108.82 142.479 108.82 142.48C108.82 142.481 108.82 142.483 108.82 142.484C108.821 142.487 108.821 142.49 108.821 142.492C108.821 142.498 108.821 142.504 108.821 142.51C108.821 142.522 108.822 142.535 108.823 142.549C108.825 142.577 108.828 142.609 108.833 142.643C108.843 142.712 108.86 142.795 108.89 142.886C108.952 143.071 109.066 143.285 109.263 143.482L110.677 142.068Z"
      fill={stroke}
      mask="url(#path-5-inside-1_1016_8606)"
    />
    <path
      d="M85.7858 147.999C119.751 147.841 147.157 120.179 146.999 86.214C146.841 52.2489 119.179 24.8427 85.2142 25.0005C51.249 25.1584 23.8428 52.8206 24.0007 86.7857C24.1585 120.751 51.8207 148.157 85.7858 147.999Z"
      stroke={stroke}
      strokeDasharray="4 4"
      strokeMiterlimit="10"
    />
    <path
      d="M92.9207 125.42C92.6262 125.273 92.3317 125.273 92.0373 125.126L70.541 145.739C70.8355 145.886 71.13 145.886 71.4244 146.033L92.9207 125.42Z"
      fill={stroke}
    />
    <path
      d="M96.4521 126.598C96.1577 126.451 96.0104 126.451 95.716 126.303L74.2197 146.622C74.5142 146.622 74.8087 146.769 75.1031 146.769L96.4521 126.598Z"
      fill={stroke}
    />
    <path
      d="M84.5257 124.537C84.084 124.537 83.7895 124.537 83.3478 124.684L63.7656 143.383C64.0601 143.53 64.2073 143.53 64.5018 143.677L84.5257 124.537Z"
      fill={stroke}
    />
    <path
      d="M78.7847 125.567C78.1958 125.715 77.6069 126.009 77.0179 126.156L62.5889 139.996C62.2945 140.585 62.1472 141.027 62 141.616L78.7847 125.567Z"
      fill={stroke}
    />
    <path
      d="M99.3951 128.071C99.1007 127.923 98.9534 127.776 98.659 127.629L78.1934 147.358C78.4878 147.358 78.7823 147.506 79.224 147.506L99.3951 128.071Z"
      fill={stroke}
    />
    <path
      d="M89.0871 124.684C88.7927 124.537 88.351 124.537 88.0565 124.537L67.002 144.708C67.2964 144.855 67.5909 144.855 67.7381 145.002L89.0871 124.684Z"
      fill={stroke}
    />
    <path
      d="M102.191 129.984C102.044 129.837 101.75 129.69 101.602 129.542L82.4619 147.8C82.7564 147.8 83.1981 147.8 83.4926 147.8L102.191 129.984Z"
      fill={stroke}
    />
    <path
      d="M110.001 140.438C109.853 140.144 109.706 139.996 109.706 139.702L103.964 145.15C104.553 145.002 105.142 144.708 105.584 144.561L110.001 140.438Z"
      fill={stroke}
    />
    <path
      d="M108.526 137.346C108.379 137.052 108.232 136.905 108.085 136.61L97.6311 146.622C98.0728 146.475 98.5145 146.475 98.9562 146.328L108.526 137.346Z"
      fill={stroke}
    />
    <path
      d="M106.612 134.696C106.464 134.549 106.317 134.254 106.17 134.107L92.0354 147.653C92.4771 147.653 92.9188 147.505 93.2133 147.505L106.612 134.696Z"
      fill={stroke}
    />
    <path
      d="M104.55 132.193C104.403 132.046 104.256 131.898 103.962 131.604L87.0295 147.8C87.4712 147.8 87.7657 147.8 88.2074 147.8L104.55 132.193Z"
      fill={stroke}
    />
    <path
      d="M86.5371 61.707C91.9118 61.707 96.3906 57.3775 96.3906 51.8535C96.3906 46.4789 92.0611 42 86.5371 42C81.1625 42 76.6836 46.3296 76.6836 51.8535C76.6836 57.3775 81.1625 61.707 86.5371 61.707Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M62.65 74.6961C65.3374 70.0679 63.6951 63.9468 59.0669 61.2595C54.4388 58.5721 48.3176 60.2144 45.6303 64.8426C42.943 69.4707 44.5852 75.5919 49.2134 78.2792C53.9909 80.9665 59.9627 79.3243 62.65 74.6961Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M47.7592 97.0904C45.0719 101.719 46.7141 107.84 51.3423 110.527C55.9705 113.214 62.0916 111.572 64.7789 106.944C67.4663 102.316 65.824 96.1946 61.1958 93.5073C56.4184 90.8199 50.4465 92.4622 47.7592 97.0904Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M122.219 78.2792C126.847 75.5919 128.49 69.62 125.802 64.8426C123.115 60.2144 117.143 58.5721 112.366 61.2595C107.738 63.9468 106.095 69.9186 108.783 74.6961C111.47 79.3243 117.591 80.9665 122.219 78.2792Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M110.381 93.507C105.753 96.1943 104.111 102.166 106.798 106.944C109.486 111.572 115.457 113.214 120.235 110.527C124.863 107.839 126.505 101.868 123.818 97.0901C121.131 92.4619 115.159 90.8197 110.381 93.507Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M87.7003 95.4845H86.3401V92.764H87.7003V95.4845ZM87.7003 90.0435H86.3401V87.323H87.7003V90.0435ZM87.7003 79.1615H86.3401V76.441H87.7003V79.1615ZM87.7003 73.7205H86.3401V71H87.7003V73.7205Z"
      fill={stroke}
    />
    <path
      d="M97.2199 89.9076L94.9075 88.5474L95.5876 87.3231L97.9 88.6834L97.2199 89.9076ZM92.459 87.1871L90.1466 85.8269L90.8267 84.6027L93.1391 85.9629L92.459 87.1871ZM83.0733 81.7461L80.7609 80.3859L81.441 79.2977L83.7534 80.6579L83.0733 81.7461ZM78.3124 79.0256L76 77.6654L76.6801 76.4412L78.9925 77.8014L78.3124 79.0256Z"
      fill={stroke}
    />
    <path
      d="M76.6801 90.0436L76 88.8194L78.3124 87.4591L78.9925 88.6834L76.6801 90.0436ZM81.441 87.3231L80.7609 86.0989L83.0733 84.7386L83.7534 85.9629L81.441 87.3231ZM90.8267 81.8821L90.1466 80.6579L92.459 79.2976L93.1391 80.5219L90.8267 81.8821ZM95.5876 79.1616L94.9075 77.9374L97.2199 76.5771L97.9 77.8014L95.5876 79.1616Z"
      fill={stroke}
    />
  </Base>
)

SensoryMotor.propTypes = BaseProps

export default SensoryMotor
