import React from "react"
import Base, { BaseProps } from "../../base"

const Telehealth = ({ fill, stroke, ...props }) => (
  <Base
    {...props}
    className="g-telehealth"
    fill="none"
    height="172"
    viewBox="0 0 172 172"
    width="172"
  >
    <circle cx="86" cy="86" fill={fill} r="86" />
    <circle cx="86" cy="86" fill={fill} r="86" />
    <path
      d="M61.7449 42.7881L60.2997 41.7993C58.8545 40.8105 58.4742 38.8328 59.463 37.3876C60.4518 35.9424 62.4295 35.5621 63.8747 36.5509L65.3199 37.5397C66.7651 38.5285 67.1455 40.5062 66.1566 41.9514C65.1678 43.3966 63.1901 43.777 61.7449 42.7881Z"
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M75.2079 51.9155L76.6531 52.9043C78.0983 53.8932 80.076 53.5129 81.0648 52.0676C82.0537 50.6224 81.6733 48.6448 80.2281 47.6559L78.7829 46.6671C77.3377 45.6783 75.36 46.0586 74.3712 47.5038C73.3824 48.949 73.7627 50.9267 75.2079 51.9155Z"
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M81.1416 51.9917C83.6517 53.6651 87.5309 59.446 80.2288 70.171C73.9155 79.4508 54.7474 90.8604 43.9463 83.4822C33.1452 76.104 36.6441 54.1976 42.9575 44.9178C50.2596 34.1928 56.9532 35.638 59.5394 37.3874"
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M43.9472 83.4824C43.9472 83.4824 32.6137 96.6415 39.6115 107.595C47.1419 119.385 61.2898 116.038 65.6254 110.409C68.5919 106.53 68.5159 99.5319 61.9744 97.0979C53.6834 93.9793 43.2626 106.758 58.3233 117.027C73.5 128 87.3797 103.715 87.3797 103.715"
      stroke={stroke}
      strokeDasharray="4 4"
      strokeMiterlimit="10"
    />
    <path
      d="M89.0527 104.019C90.607 104.019 91.867 102.759 91.867 101.205C91.867 99.6507 90.607 98.3906 89.0527 98.3906C87.4983 98.3906 86.2383 99.6507 86.2383 101.205C86.2383 102.759 87.4983 104.019 89.0527 104.019Z"
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M81.5221 105.237C80.0769 102.498 80.153 99.1516 81.9785 96.4133C84.6407 92.5341 89.9652 91.4692 93.8445 94.1314C97.7237 96.7937 98.7886 102.118 96.1264 105.997C94.3009 108.66 91.2583 110.029 88.2157 109.725"
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M108.738 131.653C110.251 131.653 111.477 130.427 111.477 128.915C111.477 127.403 110.251 126.177 108.738 126.177C107.226 126.177 106 127.403 106 128.915C106 130.427 107.226 131.653 108.738 131.653Z"
      fill={stroke}
    />
    <path
      d="M89 89.1335V70.0415C89 67.1511 91.358 64.8691 94.1724 64.8691H123.229C126.119 64.8691 128.401 67.2271 128.401 70.0415V129.828C128.401 132.718 126.043 135 123.229 135H94.1724C91.2819 135 89 132.642 89 129.828V114.615"
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path d="M89 122.83H128.401" stroke={stroke} strokeMiterlimit="10" />
    <path d="M89 75.0615H128.781" stroke={stroke} strokeMiterlimit="10" />
    <path
      d="M113.117 71.5625H104.217C103.532 71.5625 103 71.03 103 70.3454C103 69.6609 103.532 69.1284 104.217 69.1284H113.117C113.801 69.1284 114.334 69.6609 114.334 70.3454C114.334 71.03 113.801 71.5625 113.117 71.5625Z"
      fill={stroke}
    />
  </Base>
)

Telehealth.propTypes = BaseProps

export default Telehealth
