import React from "react"
import Base, { BaseProps } from "../../base"

const Nutrition = ({ fill, stroke, ...props }) => (
  <Base
    {...props}
    className="g-nutrition"
    fill="none"
    height="172"
    viewBox="0 0 172 172"
    width="172"
  >
    <circle cx="86" cy="86" fill={fill} r="86" />
    <circle cx="86" cy="86" fill={fill} r="86" />
    <path
      d="M87.5 147C121.466 147 149 119.466 149 85.5C149 51.5345 121.466 24 87.5 24C53.5345 24 26 51.5345 26 85.5C26 119.466 53.5345 147 87.5 147Z"
      stroke={stroke}
      strokeDasharray="4 4"
      strokeMiterlimit="10"
    />
    <path
      d="M65.96 28V57.366C65.96 62.426 70.0211 66.5282 75.0304 66.5282H116.93C121.939 66.5282 126 70.6304 126 75.6904V76.3951C126 81.4551 121.939 85.5573 116.93 85.5573H58.0704C53.0611 85.5573 49 89.6595 49 94.7195V95.7857C49 100.846 53.0611 104.948 58.0704 104.948H116.93C121.939 104.948 126 109.05 126 114.11C126 119.17 121.939 123.272 116.93 123.272H96.5704C91.5611 123.272 87.5 127.374 87.5 132.434V147"
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M106.282 77.9516C115.644 77.2445 122.661 69.0817 121.953 59.7195C121.246 50.3573 113.083 43.3411 103.721 44.0482C94.359 44.7554 87.3428 52.9182 88.0499 62.2803C88.7571 71.6425 96.9199 78.6588 106.282 77.9516Z"
      fill={stroke}
    />
    <path
      d="M63.5 111C67.0899 111 70 108.09 70 104.5C70 100.91 67.0899 98 63.5 98C59.9102 98 57 100.91 57 104.5C57 108.09 59.9102 111 63.5 111Z"
      fill={stroke}
    />
    <path
      d="M105.5 57.8031C105.5 57.8031 107.343 55.1451 111.575 56.2899C116.901 57.7334 115.903 69.4305 109.367 70.884C106.863 71.4415 105.5 69.8088 105.5 69.8088C105.5 69.8088 104.147 71.4415 101.633 70.884C95.0973 69.4305 94.0993 57.7334 99.4254 56.2899C103.657 55.1351 105.5 57.8031 105.5 57.8031Z"
      stroke={fill}
      strokeMiterlimit="10"
    />
    <path
      d="M108.5 53C108.5 53 105.373 55.0299 105.504 58"
      stroke={fill}
      strokeMiterlimit="10"
    />
    <path
      d="M105.5 57.5C105.5 57.5 103.434 50.9043 100.241 49C100.241 49 96.9244 53.5 104.325 54.7872"
      stroke={fill}
      strokeMiterlimit="10"
    />
  </Base>
)

Nutrition.propTypes = BaseProps

export default Nutrition
