import React from "react"
import PropTypes from "prop-types"
import Base, { BaseProps } from "../base"
import "./caret.scss"

const Caret = ({
  direction = "right",
  fill,
  fillSecondary,
  size = "small",
  stroke,
  strokeWidth,
  ...props
}) => (
  <Base
    {...props}
    className={`g-caret g-caret__${direction} --${size}`}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      d="M8 20L16 12L8 4"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
    />
  </Base>
)

Caret.propTypes = {
  ...BaseProps,
  direction: PropTypes.oneOf(["left", "right", "up", "down"]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
}

export default Caret
