import React from "react"
import PropTypes from "prop-types"
import Base, { BaseProps } from "../base"
import "./arrow.scss"

const Arrow = ({
  className,
  direction = "right",
  fill,
  fillSecondary,
  size = "small",
  stroke,
  strokeWidth,
  ...props
}) => (
  <Base
    {...props}
    className={`g-arrow g-arrow__${direction} --${size} ${className}`}
    fill={fill}
    viewBox="0 0 40 40"
  >
    <path
      d="M38.5 20C38.5 30.2173 30.2173 38.5 20 38.5C9.78273 38.5 1.49999 30.2173 1.5 20C1.5 9.78273 9.78273 1.5 20 1.5C30.2173 1.50001 38.5 9.78274 38.5 20Z"
      stroke={stroke}
      strokeWidth={strokeWidth}
    />

    <path
      d="M10 18.5C9.17157 18.5 8.5 19.1716 8.5 20C8.5 20.8284 9.17157 21.5 10 21.5L10 18.5ZM31.0607 21.0607C31.6464 20.4749 31.6464 19.5251 31.0607 18.9393L21.5147 9.3934C20.9289 8.80761 19.9792 8.80761 19.3934 9.3934C18.8076 9.97919 18.8076 10.9289 19.3934 11.5147L27.8787 20L19.3934 28.4853C18.8076 29.0711 18.8076 30.0208 19.3934 30.6066C19.9792 31.1924 20.9289 31.1924 21.5147 30.6066L31.0607 21.0607ZM10 21.5L30 21.5L30 18.5L10 18.5L10 21.5Z"
      fill={fillSecondary}
    />
  </Base>
)

Arrow.propTypes = {
  ...BaseProps,
  className: PropTypes.string,
  direction: PropTypes.oneOf(["left", "right", "up", "down"]),
  fillSecondary: PropTypes.string,
  size: PropTypes.oneOf(["small", "medium", "large"]),
}

export default Arrow
