import React from "react"
import PropTypes from "prop-types"
import Paragraph from "components/type/Paragraph"
import "./col.scss"

const Col = ({ children, label }) => (
  <div className="g-footer-col">
    {label && (
      <Paragraph className="g-footer-col-label" type="p2">
        {label}
      </Paragraph>
    )}
    <div className="g-footer-col-content">{children}</div>
  </div>
)

Col.props = {
  children: PropTypes.node,
  label: PropTypes.string,
}

Col.propTypes = Col.props

export default Col
