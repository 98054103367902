export const renderClasses = (initialClasses = "", newClasses) => {
  const boolClasses = newClasses
    ? newClasses
        .map(c => {
          const bool = c[0]
          const yesClass = c[1]
          const noClass = c[2]
          const yes = yesClass && yesClass.length > 0 ? ` ${yesClass}` : ""
          const no = noClass && noClass.length > 0 ? ` ${noClass}` : ""
          return bool ? yes : no
        })
        .join(" ")
    : ""
  const classes =
    boolClasses !== " " && boolClasses.length > 0
      ? initialClasses + boolClasses
      : initialClasses
  return initialClasses === "" ? classes.trimStart() : classes.trimEnd()
}
