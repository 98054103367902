import React from "react"
import PropTypes from "prop-types"
import { renderClasses } from "utils/renderClasses"
import Logo from "./Logo"
import Menu from "./Menu"
import Action from "./Action"
import "./topnav.scss"

const TopNav = ({ cta, menu, menuOpen, menuToggleHandler, utilityLinks }) => (
  <div className={renderClasses("g-header-top-nav", [[menuOpen, "--open"]])}>
    <div className="max-container">
      <Logo menuOpen={menuOpen} />
      <Menu {...menu} menuOpen={menuOpen} utilityLinks={utilityLinks} />
      {cta && (
        <Action
          {...cta}
          menuOpen={menuOpen}
          menuToggleHandler={menuToggleHandler}
        />
      )}
    </div>
  </div>
)

TopNav.props = {
  ...Menu.props,
  cta: PropTypes.shape(Action.props),
}

TopNav.propTypes = {
  ...TopNav.props,
  menuOpen: PropTypes.bool.isRequired,
  menuToggleHandler: PropTypes.func.isRequired,
}

export default TopNav
