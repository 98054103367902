import React from "react"
import Base, { BaseProps } from "../base"

const Plus = ({ fill, stroke, strokeWidth, ...props }) => (
  <Base
    {...props}
    className="g-plus"
    fill={fill}
    height="40"
    viewBox="0 0 40 40"
    width="40"
  >
    <path
      clipRule="evenodd"
      d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
      fill={fill}
      fillRule="evenodd"
    />
    <path
      d="M28.718 19.9999H11.2821"
      stroke={stroke}
      strokeLinecap="round"
      strokeWidth="3"
    />
    <path
      d="M20 28.718L20 11.2821"
      stroke="#F9F8F2"
      strokeLinecap="round"
      strokeWidth={strokeWidth}
    />
  </Base>
)

Plus.propTypes = BaseProps

export default Plus
