import React from "react"
import Col from "../Col"
import Links from "./Links"
import "./copyright.scss"

const Copyright = ({ utilityLinks }) => {
  return (
    <div className="g-footer-copyright">
      <Col>
        <Links utilityLinks={utilityLinks} />
      </Col>
    </div>
  )
}

Copyright.props = Links.props

Copyright.propTypes = Copyright.props

export default Copyright
