import React from "react"
import PropTypes from "prop-types"
import Base, { BaseProps } from "../base"

const Play = ({ fill, fillSecondary, stroke, strokeWidth, ...props }) => (
  <Base
    {...props}
    className="g-play"
    fill={fill}
    height="42"
    viewBox="0 0 42 42"
    width="42"
  >
    <path
      d="M21 41C32.0457 41 41 32.0457 41 21C41 9.9543 32.0457 1 21 1C9.95431 1 1 9.9543 1 21C1 32.0457 9.95431 41 21 41Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
      strokeWidth={strokeWidth}
    />
    <path
      d="M28.9011 19.2568C30.2604 20.0215 30.2604 21.9785 28.9011 22.7432L18.9805 28.3235C17.6473 29.0734 16 28.11 16 26.5803L16 15.4197C16 13.89 17.6473 12.9266 18.9805 13.6765L28.9011 19.2568Z"
      fill={fillSecondary}
    />
  </Base>
)

Play.propTypes = {
  ...BaseProps,
  fillSecondary: PropTypes.string,
}

export default Play
