import React from "react"
import Base, { BaseProps } from "../../base"

const ResearchSecondary = ({ fill, stroke, ...props }) => (
  <Base
    {...props}
    className="g-researchSecondary"
    fill="none"
    height="172"
    viewBox="0 0 172 172"
    width="172"
  >
    <circle cx="86" cy="86" fill={fill} r="86" />
    <circle cx="86" cy="86" fill={fill} r="86" />
    <path
      d="M85.2 116.2c20.324 0 36.8-16.476 36.8-36.8 0-20.324-16.476-36.8-36.8-36.8-20.324 0-36.8 16.476-36.8 36.8 0 20.324 16.476 36.8 36.8 36.8Z"
      stroke={stroke}
      strokeDasharray="4 4"
      strokeMiterlimit="10"
    />
    <path
      d="M40.4 105.7a5.4 5.4 0 1 0 0-10.8 5.4 5.4 0 0 0 0 10.8ZM60.2 84.9a5.4 5.4 0 1 0 0-10.8 5.4 5.4 0 0 0 0 10.8ZM103.9 66.1a5.4 5.4 0 1 0 0-10.8 5.4 5.4 0 0 0 0 10.8ZM130 66.1a5.4 5.4 0 1 0 0-10.8 5.4 5.4 0 0 0 0 10.8ZM85.2 84.3a5.4 5.4 0 1 0 0-10.8 5.4 5.4 0 0 0 0 10.8Z"
      fill={stroke}
    />
    <path
      d="M42.8 97.2 61 78.9h25.4l18.201-18.2h25.4M116.901 104.5l21.1 21.2c2.1 2.1 2.8 4.7.7 6.8-2.1 2.1-4.7 1.4-6.8-.7l-21.1-21.2M65.7 61.9h14.1M75.9 51.4h17.9M102.4 73.5h14.3M95.7 83.5h19.799M69.9 92.9h30.7M75.9 104h17.9"
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M84.9 120c22.368 0 40.5-18.132 40.5-40.5 0-22.367-18.132-40.5-40.5-40.5-22.367 0-40.5 18.133-40.5 40.5 0 22.368 18.133 40.5 40.5 40.5Z"
      stroke={stroke}
      strokeMiterlimit="10"
    />
  </Base>
)

ResearchSecondary.propTypes = BaseProps

export default ResearchSecondary
