import React, { forwardRef } from "react"
import PropTypes from "prop-types"
import { renderClasses } from "utils/renderClasses"
import { BaseTypeProps, ParagraphTypes } from "../props"
import "./paragraph.scss"

const Paragraph = forwardRef(
  ({ children, className, italic = false, semiBold = false, type }, ref) => (
    <p
      className={renderClasses("g-paragraph", [
        [className, className],
        [type, `-${type}`],
        [italic, "-i"],
        [semiBold, "-s"],
      ])}
      ref={ref}
    >
      {children}
    </p>
  )
)

Paragraph.props = {
  ...BaseTypeProps,
  semiBold: PropTypes.bool,
  type: PropTypes.oneOf(ParagraphTypes),
}

Paragraph.displayName = "Paragraph"

Paragraph.propTypes = Paragraph.props

export default Paragraph
