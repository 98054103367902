import React from "react"
import Base, { BaseProps } from "../../base"

const Research = ({ fill, stroke, ...props }) => (
  <Base
    {...props}
    className="g-research"
    fill="none"
    height="172"
    viewBox="0 0 172 172"
    width="172"
  >
    <circle cx="86" cy="86" fill={fill} r="86" />
    <path
      d="M38.2402 74.4525C39.2863 73.1124 42.163 69.1164 44.2418 66.3098L52.7244 74.4525L61.7771 62.6841"
      stroke={stroke}
    />
    <circle
      cx="50.4632"
      cy="69.4737"
      r="18.9632"
      stroke={stroke}
      strokeDasharray="2 2"
    />
    <circle
      cx="60.8717"
      cy="115.189"
      r="27.5632"
      stroke={stroke}
      strokeDasharray="2 2"
    />
    <circle
      cx="106.59"
      cy="68.5684"
      r="37.0684"
      stroke={stroke}
      strokeDasharray="2 2"
    />
    <path d="M48.8243 106.062L59.8578 113.552" stroke={stroke} />
    <line stroke={stroke} x1="68.1007" x2="74.5791" y1="113.845" y2="109.593" />
    <line stroke={stroke} x1="62.9393" x2="61.7246" y1="121.333" y2="126.799" />
    <circle cx="45.4834" cy="104.326" r="2.21579" stroke={stroke} />
    <circle cx="63.5888" cy="116.095" r="2.21579" stroke={stroke} />
    <circle cx="78.0732" cy="107.042" r="2.21579" stroke={stroke} />
    <circle cx="60.872" cy="131.484" r="2.21579" stroke={stroke} />
    <g clipPath="url(#clip0_1016_8078)">
      <path
        d="M116.09 72.25C117.194 72.25 118.09 71.3546 118.09 70.25C118.09 69.1454 117.194 68.25 116.09 68.25C114.985 68.25 114.09 69.1454 114.09 70.25C114.09 71.3546 114.985 72.25 116.09 72.25Z"
        fill={stroke}
      />
      <path
        d="M109.537 60.3498C110.448 60.3498 111.187 59.6114 111.187 58.7005C111.187 57.7897 110.448 57.0513 109.537 57.0513C108.625 57.0513 107.887 57.7897 107.887 58.7005C107.887 59.6114 108.625 60.3498 109.537 60.3498Z"
        fill={stroke}
      />
      <path
        d="M103.41 55.0198C103.962 55.0198 104.41 54.5721 104.41 54.0198C104.41 53.4675 103.962 53.0198 103.41 53.0198C102.858 53.0198 102.41 53.4675 102.41 54.0198C102.41 54.5721 102.858 55.0198 103.41 55.0198Z"
        fill={stroke}
      />
      <path
        d="M100.963 52.9447C101.488 52.9447 101.914 52.5193 101.914 51.9947C101.914 51.47 101.488 51.0447 100.963 51.0447C100.437 51.0447 100.012 51.47 100.012 51.9947C100.012 52.5193 100.437 52.9447 100.963 52.9447Z"
        fill={stroke}
      />
      <path
        d="M99.0469 50.5752C99.5301 50.5752 99.9219 50.1834 99.9219 49.7002C99.9219 49.2169 99.5301 48.8252 99.0469 48.8252C98.5636 48.8252 98.1719 49.2169 98.1719 49.7002C98.1719 50.1834 98.5636 50.5752 99.0469 50.5752Z"
        fill={stroke}
      />
      <path
        d="M97.3711 48.0652C97.7853 48.0652 98.1211 47.7294 98.1211 47.3152C98.1211 46.901 97.7853 46.5652 97.3711 46.5652C96.9569 46.5652 96.6211 46.901 96.6211 47.3152C96.6211 47.7294 96.9569 48.0652 97.3711 48.0652Z"
        fill={stroke}
      />
      <path
        d="M96.9266 45.5016C97.3132 45.5016 97.6266 45.1882 97.6266 44.8016C97.6266 44.415 97.3132 44.1016 96.9266 44.1016C96.54 44.1016 96.2266 44.415 96.2266 44.8016C96.2266 45.1882 96.54 45.5016 96.9266 45.5016Z"
        fill={stroke}
      />
      <path
        d="M96.8711 43.1875C97.1472 43.1875 97.3711 42.9636 97.3711 42.6875C97.3711 42.4114 97.1472 42.1875 96.8711 42.1875C96.595 42.1875 96.3711 42.4114 96.3711 42.6875C96.3711 42.9636 96.595 43.1875 96.8711 43.1875Z"
        fill={stroke}
      />
      <path
        d="M112.838 63.2762C113.86 63.2762 114.688 62.4479 114.688 61.4262C114.688 60.4044 113.86 59.5762 112.838 59.5762C111.817 59.5762 110.988 60.4044 110.988 61.4262C110.988 62.4479 111.817 63.2762 112.838 63.2762Z"
        fill={stroke}
      />
      <path
        d="M115.544 67.3651C116.621 67.3651 117.494 66.492 117.494 65.4151C117.494 64.3381 116.621 63.4651 115.544 63.4651C114.467 63.4651 113.594 64.3381 113.594 65.4151C113.594 66.492 114.467 67.3651 115.544 67.3651Z"
        fill={stroke}
      />
      <path
        d="M106.391 82.5505C107.219 82.5505 107.891 81.879 107.891 81.0505C107.891 80.2221 107.219 79.5505 106.391 79.5505C105.562 79.5505 104.891 80.2221 104.891 81.0505C104.891 81.879 105.562 82.5505 106.391 82.5505Z"
        fill={stroke}
      />
      <path
        d="M102.801 85.8627C103.76 85.8627 104.538 85.097 104.538 84.1524C104.538 83.2078 103.76 82.442 102.801 82.442C101.842 82.442 101.064 83.2078 101.064 84.1524C101.064 85.097 101.842 85.8627 102.801 85.8627Z"
        fill={stroke}
      />
      <path
        d="M99.4539 88.8646C100.255 88.8646 100.904 88.2154 100.904 87.4146C100.904 86.6138 100.255 85.9646 99.4539 85.9646C98.6531 85.9646 98.0039 86.6138 98.0039 87.4146C98.0039 88.2154 98.6531 88.8646 99.4539 88.8646Z"
        fill={stroke}
      />
      <path
        d="M97.406 91.9508C98.1253 91.9508 98.7084 91.3765 98.7084 90.668C98.7084 89.9596 98.1253 89.3853 97.406 89.3853C96.6866 89.3853 96.1035 89.9596 96.1035 90.668C96.1035 91.3765 96.6866 91.9508 97.406 91.9508Z"
        fill={stroke}
      />
      <path
        d="M96.9912 95.4326C97.6649 95.4326 98.211 94.8947 98.211 94.2312C98.211 93.5678 97.6649 93.0299 96.9912 93.0299C96.3176 93.0299 95.7715 93.5678 95.7715 94.2312C95.7715 94.8947 96.3176 95.4326 96.9912 95.4326Z"
        fill={stroke}
      />
      <path
        d="M110.534 80.1208C111.573 80.1208 112.415 79.2912 112.415 78.2679C112.415 77.2446 111.573 76.415 110.534 76.415C109.495 76.415 108.652 77.2446 108.652 78.2679C108.652 79.2912 109.495 80.1208 110.534 80.1208Z"
        fill={stroke}
      />
      <path
        d="M114.088 76.7204C115.184 76.7204 116.073 75.8453 116.073 74.7657C116.073 73.6862 115.184 72.811 114.088 72.811C112.992 72.811 112.104 73.6862 112.104 74.7657C112.104 75.8453 112.992 76.7204 114.088 76.7204Z"
        fill={stroke}
      />
      <path
        d="M97.0039 69C98.1085 69 99.0039 68.1046 99.0039 67C99.0039 65.8954 98.1085 65 97.0039 65C95.8993 65 95.0039 65.8954 95.0039 67C95.0039 68.1046 95.8993 69 97.0039 69Z"
        fill={stroke}
      />
      <path
        d="M103.246 80.2703C104.075 80.2703 104.746 79.5987 104.746 78.7703C104.746 77.9418 104.075 77.2703 103.246 77.2703C102.418 77.2703 101.746 77.9418 101.746 78.7703C101.746 79.5987 102.418 80.2703 103.246 80.2703Z"
        fill={stroke}
      />
      <path
        d="M109.445 84.4827C109.998 84.4827 110.445 84.035 110.445 83.4827C110.445 82.9304 109.998 82.4827 109.445 82.4827C108.893 82.4827 108.445 82.9304 108.445 83.4827C108.445 84.035 108.893 84.4827 109.445 84.4827Z"
        fill={stroke}
      />
      <path
        d="M111.99 86.5021C112.487 86.5021 112.89 86.0992 112.89 85.6021C112.89 85.1051 112.487 84.7021 111.99 84.7021C111.493 84.7021 111.09 85.1051 111.09 85.6021C111.09 86.0992 111.493 86.5021 111.99 86.5021Z"
        fill={stroke}
      />
      <path
        d="M113.949 88.8345C114.432 88.8345 114.824 88.4427 114.824 87.9595C114.824 87.4762 114.432 87.0845 113.949 87.0845C113.466 87.0845 113.074 87.4762 113.074 87.9595C113.074 88.4427 113.466 88.8345 113.949 88.8345Z"
        fill={stroke}
      />
      <path
        d="M115.563 91.0073C115.977 91.0073 116.312 90.6715 116.312 90.2573C116.312 89.8431 115.977 89.5073 115.563 89.5073C115.148 89.5073 114.812 89.8431 114.812 90.2573C114.812 90.6715 115.148 91.0073 115.563 91.0073Z"
        fill={stroke}
      />
      <path
        d="M116.071 93.534C116.458 93.534 116.771 93.2206 116.771 92.834C116.771 92.4474 116.458 92.134 116.071 92.134C115.684 92.134 115.371 92.4474 115.371 92.834C115.371 93.2206 115.684 93.534 116.071 93.534Z"
        fill={stroke}
      />
      <path
        d="M116.016 95.333C116.292 95.333 116.516 95.1091 116.516 94.833C116.516 94.5569 116.292 94.333 116.016 94.333C115.739 94.333 115.516 94.5569 115.516 94.833C115.516 95.1091 115.739 95.333 116.016 95.333Z"
        fill={stroke}
      />
      <path
        d="M100.804 77.6C101.798 77.6 102.604 76.7941 102.604 75.8C102.604 74.8059 101.798 74 100.804 74C99.8098 74 99.0039 74.8059 99.0039 75.8C99.0039 76.7941 99.8098 77.6 100.804 77.6Z"
        fill={stroke}
      />
      <path
        d="M97.9539 73.9C99.0309 73.9 99.9039 73.027 99.9039 71.95C99.9039 70.873 99.0309 70 97.9539 70C96.877 70 96.0039 70.873 96.0039 71.95C96.0039 73.027 96.877 73.9 97.9539 73.9Z"
        fill={stroke}
      />
      <path
        d="M106.266 57.7708C107.094 57.7708 107.766 57.0992 107.766 56.2708C107.766 55.4423 107.094 54.7708 106.266 54.7708C105.437 54.7708 104.766 55.4423 104.766 56.2708C104.766 57.0992 105.437 57.7708 106.266 57.7708Z"
        fill={stroke}
      />
      <path
        d="M110.201 55.178C111.161 55.178 111.938 54.4123 111.938 53.4677C111.938 52.5231 111.161 51.7573 110.201 51.7573C109.242 51.7573 108.465 52.5231 108.465 53.4677C108.465 54.4123 109.242 55.178 110.201 55.178Z"
        fill={stroke}
      />
      <path
        d="M113.428 51.6556C114.25 51.6556 114.916 50.9992 114.916 50.1895C114.916 49.3799 114.25 48.7235 113.428 48.7235C112.606 48.7235 111.939 49.3799 111.939 50.1895C111.939 50.9992 112.606 51.6556 113.428 51.6556Z"
        fill={stroke}
      />
      <path
        d="M115.597 48.2348C116.317 48.2348 116.9 47.6605 116.9 46.9521C116.9 46.2436 116.317 45.6693 115.597 45.6693C114.878 45.6693 114.295 46.2436 114.295 46.9521C114.295 47.6605 114.878 48.2348 115.597 48.2348Z"
        fill={stroke}
      />
      <path
        d="M116.011 44.5901C116.684 44.5901 117.231 44.0523 117.231 43.3888C117.231 42.7254 116.684 42.1875 116.011 42.1875C115.337 42.1875 114.791 42.7254 114.791 43.3888C114.791 44.0523 115.337 44.5901 116.011 44.5901Z"
        fill={stroke}
      />
      <path
        d="M102.34 60.9993C103.306 60.9993 104.09 60.2158 104.09 59.2493C104.09 58.2828 103.306 57.4993 102.34 57.4993C101.373 57.4993 100.59 58.2828 100.59 59.2493C100.59 60.2158 101.373 60.9993 102.34 60.9993Z"
        fill={stroke}
      />
      <path
        d="M98.6797 64.3997C99.6462 64.3997 100.43 63.6162 100.43 62.6497C100.43 61.6832 99.6462 60.8997 98.6797 60.8997C97.7132 60.8997 96.9297 61.6832 96.9297 62.6497C96.9297 63.6162 97.7132 64.3997 98.6797 64.3997Z"
        fill={stroke}
      />
      <path
        d="M115.555 43.3889H99.5547"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
      <path
        d="M115.004 47H101.004"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
      <path
        d="M113.004 50H103.004"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
      <path
        d="M109.441 53.4678H106.441"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
      <path
        d="M103.332 59.3521H106.332"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
      <path
        d="M99.8789 62.8542H110.199"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
      <path
        d="M98.9141 67.3135H112.414"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
      <path
        d="M99.4102 71.9546H110.988"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
      <path
        d="M101.996 75.7875H107.702"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
      <path
        d="M104.537 84.1523H106.439"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
      <path
        d="M100.238 87.5875L110.238 87.5875"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
      <path
        d="M98.0039 90.6875L113.004 90.6875"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
      <path
        d="M97.7383 94.2312H114.738"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeWidth="0.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_1016_8078">
        <rect
          fill="white"
          height="53.245"
          transform="translate(94.7383 42.1875)"
          width="23.5269"
        />
      </clipPath>
    </defs>
  </Base>
)

Research.propTypes = BaseProps

export default Research
