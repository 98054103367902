import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { renderClasses } from "utils/renderClasses"
import Icon from "components/elements/Icon"
import "./logo.scss"

const Logo = ({ menuOpen }) => (
  <div
    className={renderClasses("g-header-top-nav-logo", [[menuOpen, "--open"]])}
  >
    <Link aria-label="Navigate to Home page" to="/">
      <Icon
        aria-label=""
        fill={menuOpen ? "#ffffff" : "#10389A"}
        icon="Logo"
        inverted={menuOpen}
      />
    </Link>
  </div>
)

Logo.props = {
  menuOpen: PropTypes.bool.isRequired,
}

Logo.propTypes = Logo.props

export default Logo
