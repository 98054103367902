import React from "react"
import Base, { BaseProps } from "../base"
import "./closeBtn.scss"

const CloseBtn = ({ fill, stroke, strokeWidth, ...props }) => (
  <Base
    {...props}
    className="g-closeBtn"
    fill="none"
    height="36"
    viewBox="0 0 36 36"
    width="36"
  >
    <path
      d="M10 25.5566L25.5563 10.0003"
      fill={fill}
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
    />
    <path
      d="M10 10L25.5563 25.5563"
      fill={fill}
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
    />
  </Base>
)

CloseBtn.propTypes = BaseProps

export default CloseBtn
