import React, { useEffect, useState, useRef } from "react"
import { renderClasses } from "utils/renderClasses"
import TopNav from "./TopNav"
import UtilityNav from "./UtilityNav"
import "./header.scss"

/**
 * These were all set by feel and are used to set tolerances for when the
 * nav should toggle, and the "velocity" required to trigger a change.
 */
const TOLERANCES = {
  HIDE: -10,
  SHOW: 20,
}

const Header = ({ announcement, cta, menu, utilityLinks }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [navHidden, setNavHidden] = useState(false)
  const [offset, setOffset] = useState(0)
  const prevOffset = usePrevious(offset)

  const toggleMobileMenu = () => {
    setMenuOpen(prevState => !prevState)
  }

  useEffect(() => {
    if (typeof window !== "undefined") {
      const delta = prevOffset - offset

      if (offset <= 100) {
        setNavHidden(false)
      } else {
        // Show/hide depending on how fast scroll is happening in a direction
        if (delta >= TOLERANCES.SHOW) {
          setNavHidden(false)
        } else if (delta <= TOLERANCES.HIDE) {
          setNavHidden(true)
        }
      }
    }
  }, [offset, prevOffset])

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.onscroll = () => {
        // This is to account for negative offset in Safari because of
        // rubber banding
        setOffset(Math.max(0, window.pageYOffset))
      }
    }
  }, [])

  useEffect(() => {
    if (menuOpen) {
      // Create a condition that targets viewports at least 1024px wide
      const mediaQuery = window.matchMedia("(min-width: 1024px)")

      const handleTabletChange = e => {
        // Check if the media query is true
        if (e.matches) {
          setMenuOpen(false)
        }
      }

      // Register event listener
      mediaQuery.addEventListener("change", handleTabletChange)

      // Initial check
      handleTabletChange(mediaQuery)
    }
  }, [menuOpen])

  return (
    <header
      className={renderClasses("g-header", [
        [menuOpen, "--open"],
        [navHidden, "--hidden"],
      ])}
    >
      <UtilityNav announcement={announcement} utilityLinks={utilityLinks} />
      <TopNav
        cta={cta}
        menu={menu}
        menuOpen={menuOpen}
        menuToggleHandler={toggleMobileMenu}
        utilityLinks={utilityLinks}
      />
    </header>
  )
}

function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef()
  // Store current value in ref
  useEffect(() => {
    ref.current = value
  }, [value]) // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current
}

Header.props = {
  ...UtilityNav.props,
  ...TopNav.props,
}

Header.propTypes = Header.props

export default Header
