import React, { forwardRef } from "react"
import { renderClasses } from "utils/renderClasses"
import { BaseTypeProps } from "../props"
import "./heading4.scss"

const Heading4 = forwardRef(({ children, className, italic = false }, ref) => (
  <h4
    className={renderClasses("g-heading-tag g-heading4", [
      [className, className],
      [italic, "-i"],
      [true, "-m"],
    ])}
    ref={ref}
  >
    {children}
  </h4>
))

Heading4.props = BaseTypeProps

Heading4.displayName = "Heading4"

Heading4.propTypes = Heading4.props

export default Heading4
