import { COLORS } from "./constants"

export const iconTheme = (theme, defaults = { fill: null, stroke: null }) => {
  let fill, stroke

  switch (theme) {
    case "DarkBlue":
      fill = COLORS.darkBlue
      stroke = COLORS.pastelBlue
      break
    case "Pink":
      fill = COLORS.pastelPink
      stroke = COLORS.darkGreen
      break
    case "Green":
      fill = COLORS.darkGreen
      stroke = COLORS.pastelGreen
      break
    case "Yellow":
      fill = COLORS.darkYellow
      stroke = COLORS.pastelYellow
      break
    case "Blue":
      fill = COLORS.corticaBlue
      stroke = COLORS.pastelBlue
      break
    default:
      fill = defaults.fill
      stroke = defaults.stroke
  }

  return {
    fill,
    stroke,
  }
}
