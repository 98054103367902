import React, { forwardRef } from "react"
import PropTypes from "prop-types"
import { renderClasses } from "utils/renderClasses"
import { BaseTypeProps } from "../props"
import "./heading3.scss"

const Heading3 = forwardRef(
  ({ children, className, italic = false, medium = false }, ref) => (
    <h3
      className={renderClasses("g-heading-tag g-heading3", [
        [className, className],
        [italic, "-i"],
        [medium, "-m"],
      ])}
      ref={ref}
    >
      {children}
    </h3>
  )
)

Heading3.props = {
  ...BaseTypeProps,
  medium: PropTypes.bool,
}

Heading3.displayName = "Heading 3"

Heading3.propTypes = Heading3.props

export default Heading3
