import React from "react"
import Base, { BaseProps } from "../../base"

const WholeBody = ({ fill, stroke, ...props }) => (
  <Base
    {...props}
    className="g-wholeBody"
    fill="none"
    height="172"
    viewBox="0 0 172 172"
    width="172"
  >
    <circle cx="86" cy="86" fill={fill} r="86" />
    <circle cx="86" cy="86" fill={fill} r="86" />
    <g clipPath="url(#a)" stroke={stroke} strokeMiterlimit="10">
      <path d="M87.3 132.502c25.516 0 46.201-20.685 46.201-46.202s-20.685-46.202-46.202-46.202c-25.516 0-46.201 20.685-46.201 46.202s20.685 46.202 46.201 46.202Z" />
      <path
        d="M119.865 54.323c0 3.801-14.558 8.64-32.545 8.64-17.987 0-32.525-4.858-32.525-8.64 0-3.782 14.558-7.465 32.545-7.465 17.987 0 32.525 3.664 32.525 7.465ZM120.159 118.081c0 3.742-14.715 8.543-32.859 8.543-18.144 0-32.859-4.801-32.859-8.543 0-3.743 14.715-7.387 32.859-7.387 18.144 0 32.859 3.644 32.859 7.387ZM130.759 70.762c0 4.076-21.396 7.936-43.263 7.936-21.866 0-43.537-4.174-43.537-8.23s21.67-7.504 43.537-7.504c21.867 0 43.263 3.742 43.263 7.798Z"
        strokeDasharray="4 4"
      />
      <path
        d="M130.504 102.7c0 4.095-21.318 7.994-43.204 7.994s-43.204-4.193-43.204-8.288c0-4.095 21.318-7.7 43.223-7.7 21.906 0 43.185 3.899 43.185 7.994ZM133.501 86.535c0 4.526-20.142 8.17-46.182 8.17-26.04 0-46.221-3.663-46.221-8.17s20.162-7.818 46.221-7.818c26.06 0 46.182 3.292 46.182 7.818Z"
        strokeDasharray="4 4"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path d="M0 0h92.6v92.6H0z" fill="#fff" transform="translate(41 40)" />
      </clipPath>
    </defs>
  </Base>
)

WholeBody.propTypes = BaseProps

export default WholeBody
