import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { renderClasses } from "utils/renderClasses"
import "./flipText.scss"

/* Flip Text */
/**
 * An array of text that animates
 */
const FlipText = ({ text }) => {
  const [transition, setTransition] = useState(false)
  const [index, setIndex] = useState(0)
  const indexRef = useRef(index)
  indexRef.current = index

  const timeBetween = 2750

  useEffect(() => {
    setTransition(true)

    const timer = setTimeout(() => {
      setTransition(false)
      setIndex((indexRef.current + 1) % text.length)
    }, timeBetween)

    return () => {
      clearTimeout(timer)
    }
  }, [index, text])

  /**
   * !!! Note to future developers !!!
   *
   * A previous version of this component had a more sensible return statement,
   * saved below for posterity. This version had a bug that cause the component
   * to cycle between text[0] and text[1] endlessly. For a reason I was not able
   * to determine, any inner HTML elements would not update from their original
   * state, while the outer one did. The "solution" here was to only have a
   * single div to update, and use dangerouslySetInnerHTML to create and update
   * the children.
   *
   * --- Old version -----------------------------------------------------------
   * <div className={...}>
   *   <div className="flip-text__inner flip-text__current">
   *     {text[index % text.length]}
   *   </div>
   *   <div className="flip-text__inner flip-text__next">
   *     {text[(index + 1) % text.length]}
   *   </div>
   * </div>
   * ---------------------------------------------------------------------------
   *
   * Since dangerouslySetInnerHTML expects raw HTML returned as a string, and
   * multiline HTML was preserving whitespace and causing incorrect formatting,
   * each individual part is stored in a separate variable and concatenated to
   * remove whitespace while preserving readability.
   */

  return (
    <div
      className={renderClasses("flip-text", [
        [transition, "flip-text--transition mobile-font"],
      ])}
      dangerouslySetInnerHTML={{
        __html: (() => {
          const outText = text[index % text.length]
          const inText = text[(index + 1) % text.length]
          const outElem = `<div class="flip-text__inner flip-text__current">${outText}</div>`
          const inElem = `<div class="flip-text__inner flip-text__next">${inText}</div>`
          return `${outElem}${inElem}`
        })(),
      }}
    />
  )
}

FlipText.props = {
  text: PropTypes.arrayOf(PropTypes.string).isRequired,
}

FlipText.propTypes = FlipText.props

export default FlipText
