import React from "react"
import Base, { BaseProps } from "../../base"

const Neurodevelopment = ({ fill, stroke, ...props }) => (
  <Base
    {...props}
    className="g-neurodevelopment"
    fill="none"
    height="172"
    viewBox="0 0 172 172"
    width="172"
  >
    <circle cx="86" cy="86" fill={fill} r="86" />
    <path
      d="M54.2031 78.53C54.3112 78.2212 56.7501 68.6353 56.7501 68.6353L59.7139 87.1898M78.253 95.0623L66.6294 92.9167"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeWidth="0.5"
    />
    <path
      d="M59.6992 87.2052L66.6302 92.9166L71.1221 100.048L84.8914 99.5388L88.6579 106.161L98.124 118.703L95.0794 107.396L107.521 107.597L116.088 97.81L118.743 90.3542L120.164 82.8212L115.888 75.1648"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeWidth="0.5"
    />
    <path
      d="M61.4297 64.8683L70.9231 61.1018L72.3432 75.1643V88.4242"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeWidth="0.5"
    />
    <path
      d="M65.168 73.5898L66.6305 82.6047"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeWidth="0.5"
    />
    <path
      d="M93.3533 61.6115L102.322 63.9578L107.215 68.635L87.5029 70.3794M80.0278 77.372L87.5029 59.6665L78.4727 60.284L86.7311 79.7492L91.2694 99.23L106.104 97.5937L118.746 90.354"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeWidth="0.5"
    />
    <path
      d="M111.596 69.7158L113.34 82.6052L120.163 82.8213"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeWidth="0.5"
    />
    <path
      d="M78.2559 95.0466L86.7304 79.7492L101.92 82.821L99.8822 74.1458"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeWidth="0.5"
    />
    <path
      d="M101.916 82.821L112.83 90.4621"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeWidth="0.5"
    />
    <path
      d="M91.9277 87.0972L106.098 97.5939"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeWidth="0.5"
    />
    <path
      d="M95.8027 107.118L102.224 102.703L115.469 98.1495"
      stroke={stroke}
      strokeMiterlimit="10"
      strokeWidth="0.5"
    />
    <path
      d="M54.2008 80.7838C55.275 80.7838 56.1458 79.913 56.1458 78.8388C56.1458 77.7646 55.275 76.8938 54.2008 76.8938C53.1267 76.8938 52.2559 77.7646 52.2559 78.8388C52.2559 79.913 53.1267 80.7838 54.2008 80.7838Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M56.6403 71.213C57.7145 71.213 58.5853 70.3422 58.5853 69.268C58.5853 68.1938 57.7145 67.323 56.6403 67.323C55.5661 67.323 54.6953 68.1938 54.6953 69.268C54.6953 70.3422 55.5661 71.213 56.6403 71.213Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M63.8649 74.2386C64.9391 74.2386 65.8099 73.3678 65.8099 72.2936C65.8099 71.2194 64.9391 70.3486 63.8649 70.3486C62.7907 70.3486 61.9199 71.2194 61.9199 72.2936C61.9199 73.3678 62.7907 74.2386 63.8649 74.2386Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M66.1637 84.2721C67.2379 84.2721 68.1087 83.4013 68.1087 82.3271C68.1087 81.2529 67.2379 80.3821 66.1637 80.3821C65.0895 80.3821 64.2188 81.2529 64.2188 82.3271C64.2188 83.4013 65.0895 84.2721 66.1637 84.2721Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M60.0036 89.1961C61.0778 89.1961 61.9486 88.3253 61.9486 87.2511C61.9486 86.177 61.0778 85.3062 60.0036 85.3062C58.9294 85.3062 58.0586 86.177 58.0586 87.2511C58.0586 88.3253 58.9294 89.1961 60.0036 89.1961Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M72.3376 89.7523C73.4117 89.7523 74.2825 88.8815 74.2825 87.8073C74.2825 86.7331 73.4117 85.8623 72.3376 85.8623C71.2634 85.8623 70.3926 86.7331 70.3926 87.8073C70.3926 88.8815 71.2634 89.7523 72.3376 89.7523Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M72.3376 77.5728C73.4117 77.5728 74.2825 76.702 74.2825 75.6278C74.2825 74.5537 73.4117 73.6829 72.3376 73.6829C71.2634 73.6829 70.3926 74.5537 70.3926 75.6278C70.3926 76.702 71.2634 77.5728 72.3376 77.5728Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M70.7184 63.5567C71.7926 63.5567 72.6634 62.6859 72.6634 61.6117C72.6634 60.5375 71.7926 59.6667 70.7184 59.6667C69.6442 59.6667 68.7734 60.5375 68.7734 61.6117C68.7734 62.6859 69.6442 63.5567 70.7184 63.5567Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M62.2887 67.2154C63.3629 67.2154 64.2337 66.3446 64.2337 65.2704C64.2337 64.1962 63.3629 63.3254 62.2887 63.3254C61.2145 63.3254 60.3438 64.1962 60.3438 65.2704C60.3438 66.3446 61.2145 67.2154 62.2887 67.2154Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M80.0758 81.262C81.15 81.262 82.0208 80.3912 82.0208 79.3171C82.0208 78.2429 81.15 77.3721 80.0758 77.3721C79.0017 77.3721 78.1309 78.2429 78.1309 79.3171C78.1309 80.3912 79.0017 81.262 80.0758 81.262Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M78.7145 62.6922C79.7887 62.6922 80.6595 61.8214 80.6595 60.7472C80.6595 59.673 79.7887 58.8022 78.7145 58.8022C77.6403 58.8022 76.7695 59.673 76.7695 60.7472C76.7695 61.8214 77.6403 62.6922 78.7145 62.6922Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M86.279 62.3526C87.3532 62.3526 88.224 61.4818 88.224 60.4076C88.224 59.3334 87.3532 58.4626 86.279 58.4626C85.2048 58.4626 84.334 59.3334 84.334 60.4076C84.334 61.4818 85.2048 62.3526 86.279 62.3526Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M93.2126 63.5567C94.2867 63.5567 95.1575 62.6859 95.1575 61.6117C95.1575 60.5375 94.2867 59.6667 93.2126 59.6667C92.1384 59.6667 91.2676 60.5375 91.2676 61.6117C91.2676 62.6859 92.1384 63.5567 93.2126 63.5567Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M101.502 65.4862C102.576 65.4862 103.447 64.6154 103.447 63.5412C103.447 62.467 102.576 61.5962 101.502 61.5962C100.427 61.5962 99.5566 62.467 99.5566 63.5412C99.5566 64.6154 100.427 65.4862 101.502 65.4862Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M106.808 70.5799C107.882 70.5799 108.753 69.7091 108.753 68.6349C108.753 67.5607 107.882 66.6899 106.808 66.6899C105.734 66.6899 104.863 67.5607 104.863 68.6349C104.863 69.7091 105.734 70.5799 106.808 70.5799Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M111.593 71.6607C112.668 71.6607 113.538 70.7899 113.538 69.7157C113.538 68.6416 112.668 67.7708 111.593 67.7708C110.519 67.7708 109.648 68.6416 109.648 69.7157C109.648 70.7899 110.519 71.6607 111.593 71.6607Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M113.539 85.1212C114.613 85.1212 115.484 84.2504 115.484 83.1762C115.484 82.102 114.613 81.2312 113.539 81.2312C112.465 81.2312 111.594 82.102 111.594 83.1762C111.594 84.2504 112.465 85.1212 113.539 85.1212Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M120.162 84.5501C121.236 84.5501 122.107 83.6793 122.107 82.6051C122.107 81.531 121.236 80.6602 120.162 80.6602C119.088 80.6602 118.217 81.531 118.217 82.6051C118.217 83.6793 119.088 84.5501 120.162 84.5501Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M116.285 76.7081C117.359 76.7081 118.23 75.8373 118.23 74.7631C118.23 73.6889 117.359 72.8181 116.285 72.8181C115.211 72.8181 114.34 73.6889 114.34 74.7631C114.34 75.8373 115.211 76.7081 116.285 76.7081Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M100.275 76.2764C101.349 76.2764 102.22 75.4056 102.22 74.3315C102.22 73.2573 101.349 72.3865 100.275 72.3865C99.2009 72.3865 98.3301 73.2573 98.3301 74.3315C98.3301 75.4056 99.2009 76.2764 100.275 76.2764Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M101.502 83.7938C102.576 83.7938 103.447 82.923 103.447 81.8488C103.447 80.7746 102.576 79.9038 101.502 79.9038C100.427 79.9038 99.5566 80.7746 99.5566 81.8488C99.5566 82.923 100.427 83.7938 101.502 83.7938Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M112.828 91.6971C113.902 91.6971 114.773 90.8263 114.773 89.7521C114.773 88.6779 113.902 87.8071 112.828 87.8071C111.754 87.8071 110.883 88.6779 110.883 89.7521C110.883 90.8263 111.754 91.6971 112.828 91.6971Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M119.082 92.762C120.156 92.762 121.027 91.8912 121.027 90.8171C121.027 89.7429 120.156 88.8721 119.082 88.8721C118.008 88.8721 117.137 89.7429 117.137 90.8171C117.137 91.8912 118.008 92.762 119.082 92.762Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M116.285 99.5387C117.359 99.5387 118.23 98.6679 118.23 97.5937C118.23 96.5195 117.359 95.6487 116.285 95.6487C115.211 95.6487 114.34 96.5195 114.34 97.5937C114.34 98.6679 115.211 99.5387 116.285 99.5387Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M108.025 109.063C109.099 109.063 109.97 108.192 109.97 107.118C109.97 106.044 109.099 105.173 108.025 105.173C106.951 105.173 106.08 106.044 106.08 107.118C106.08 108.192 106.951 109.063 108.025 109.063Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M97.904 120.162C98.9782 120.162 99.849 119.291 99.849 118.217C99.849 117.143 98.9782 116.272 97.904 116.272C96.8298 116.272 95.959 117.143 95.959 118.217C95.959 119.291 96.8298 120.162 97.904 120.162Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M95.1383 109.542C96.2125 109.542 97.0833 108.671 97.0833 107.597C97.0833 106.522 96.2125 105.652 95.1383 105.652C94.0642 105.652 93.1934 106.522 93.1934 107.597C93.1934 108.671 94.0642 109.542 95.1383 109.542Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M101.502 105.189C102.576 105.189 103.447 104.318 103.447 103.244C103.447 102.169 102.576 101.299 101.502 101.299C100.427 101.299 99.5566 102.169 99.5566 103.244C99.5566 104.318 100.427 105.189 101.502 105.189Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M106.097 99.7703C107.172 99.7703 108.042 98.8995 108.042 97.8254C108.042 96.7512 107.172 95.8804 106.097 95.8804C105.023 95.8804 104.152 96.7512 104.152 97.8254C104.152 98.8995 105.023 99.7703 106.097 99.7703Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M92.8376 89.1961C93.9117 89.1961 94.7825 88.3253 94.7825 87.2511C94.7825 86.177 93.9117 85.3062 92.8376 85.3062C91.7634 85.3062 90.8926 86.177 90.8926 87.2511C90.8926 88.3253 91.7634 89.1961 92.8376 89.1961Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M86.818 82.6207C87.8922 82.6207 88.763 81.7499 88.763 80.6757C88.763 79.6015 87.8922 78.7307 86.818 78.7307C85.7438 78.7307 84.873 79.6015 84.873 80.6757C84.873 81.7499 85.7438 82.6207 86.818 82.6207Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M78.7145 96.5904C79.7887 96.5904 80.6595 95.7196 80.6595 94.6454C80.6595 93.5712 79.7887 92.7004 78.7145 92.7004C77.6403 92.7004 76.7695 93.5712 76.7695 94.6454C76.7695 95.7196 77.6403 96.5904 78.7145 96.5904Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M66.8883 94.7074C67.9625 94.7074 68.8333 93.8366 68.8333 92.7624C68.8333 91.6882 67.9625 90.8174 66.8883 90.8174C65.8142 90.8174 64.9434 91.6882 64.9434 92.7624C64.9434 93.8366 65.8142 94.7074 66.8883 94.7074Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M70.8239 101.483C71.8981 101.483 72.7689 100.613 72.7689 99.5385C72.7689 98.4643 71.8981 97.5935 70.8239 97.5935C69.7497 97.5935 68.8789 98.4643 68.8789 99.5385C68.8789 100.613 69.7497 101.483 70.8239 101.483Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M84.8864 101.483C85.9606 101.483 86.8314 100.613 86.8314 99.5385C86.8314 98.4643 85.9606 97.5935 84.8864 97.5935C83.8122 97.5935 82.9414 98.4643 82.9414 99.5385C82.9414 100.613 83.8122 101.483 84.8864 101.483Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M91.5446 100.867C92.6188 100.867 93.4896 99.9957 93.4896 98.9215C93.4896 97.8474 92.6188 96.9766 91.5446 96.9766C90.4704 96.9766 89.5996 97.8474 89.5996 98.9215C89.5996 99.9957 90.4704 100.867 91.5446 100.867Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M88.7614 107.597C89.8356 107.597 90.7064 106.726 90.7064 105.652C90.7064 104.578 89.8356 103.707 88.7614 103.707C87.6872 103.707 86.8164 104.578 86.8164 105.652C86.8164 106.726 87.6872 107.597 88.7614 107.597Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M88.2223 72.5249C89.2965 72.5249 90.1673 71.6541 90.1673 70.5799C90.1673 69.5057 89.2965 68.6349 88.2223 68.6349C87.1481 68.6349 86.2773 69.5057 86.2773 70.5799C86.2773 71.6541 87.1481 72.5249 88.2223 72.5249Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M33.9945 76.552C34.1573 76.7751 34.4701 76.824 34.6931 76.6613L38.3284 74.0089C38.5515 73.8462 38.6004 73.5334 38.4376 73.3103C38.2749 73.0872 37.9621 73.0383 37.739 73.2011L34.5077 75.5587L32.15 72.3274C31.9873 72.1043 31.6745 72.0554 31.4514 72.2182C31.2283 72.3809 31.1794 72.6937 31.3422 72.9168L33.9945 76.552ZM78.4701 32.8843C78.6329 32.6612 78.584 32.3484 78.3609 32.1857L74.7256 29.5334C74.5025 29.3706 74.1897 29.4195 74.027 29.6426C73.8642 29.8657 73.9131 30.1785 74.1362 30.3412L77.3676 32.6988L75.01 35.9302C74.8472 36.1533 74.8961 36.466 75.1192 36.6288C75.3423 36.7916 75.655 36.7427 75.8178 36.5196L78.4701 32.8843ZM34.8924 76.3346C35.0131 75.5625 35.1509 74.796 35.3052 74.0355L34.3252 73.8366C34.1679 74.6118 34.0275 75.3931 33.9044 76.1801L34.8924 76.3346ZM36.4403 69.506C36.889 68.0074 37.4035 66.5371 37.9805 65.0985L37.0524 64.7262C36.4642 66.1927 35.9397 67.6915 35.4824 69.2192L36.4403 69.506ZM39.9125 60.8484C40.622 59.458 41.3922 58.1036 42.2198 56.7888L41.3735 56.2561C40.53 57.5962 39.7449 58.9766 39.0218 60.3939L39.9125 60.8484ZM44.8819 52.9523C45.8267 51.7131 46.8259 50.5175 47.876 49.3691L47.1381 48.6942C46.0679 49.8646 45.0495 51.083 44.0866 52.346L44.8819 52.9523ZM51.178 46.0671C52.3262 45.0169 53.5218 44.0176 54.7611 43.0728L54.1548 42.2775C52.8918 43.2405 51.6733 44.2589 50.5031 45.3291L51.178 46.0671ZM58.5975 40.4106C59.9122 39.5831 61.2666 38.8128 62.6571 38.1034L62.2026 37.2126C60.7853 37.9358 59.4048 38.7209 58.0648 39.5643L58.5975 40.4106ZM66.9072 36.1714C68.3457 35.5945 69.8161 35.08 71.3148 34.6314L71.028 33.6734C69.5002 34.1307 68.0014 34.6552 66.535 35.2433L66.9072 36.1714ZM75.8443 33.4964C76.6048 33.342 77.3713 33.2043 78.1434 33.0836L77.9889 32.0956C77.2019 32.2186 76.4206 32.359 75.6454 32.5164L75.8443 33.4964Z"
      fill={stroke}
    />
    <path
      d="M78.3565 138.007C78.5813 137.847 78.6336 137.535 78.4732 137.31L75.8602 133.646C75.6999 133.421 75.3876 133.369 75.1628 133.529C74.938 133.69 74.8857 134.002 75.0461 134.227L77.3688 137.483L74.1122 139.806C73.8874 139.966 73.8351 140.279 73.9955 140.503C74.1558 140.728 74.4681 140.78 74.6929 140.62L78.3565 138.007ZM34.6938 92.4891C34.471 92.326 34.1581 92.3744 33.995 92.5972L31.3368 96.2282C31.1736 96.451 31.222 96.7638 31.4448 96.927C31.6677 97.0901 31.9805 97.0417 32.1436 96.8189L34.5065 93.5914L37.734 95.9542C37.9568 96.1174 38.2697 96.069 38.4328 95.8462C38.596 95.6234 38.5476 95.3105 38.3247 95.1474L34.6938 92.4891ZM33.9043 92.969C34.0274 93.7646 34.1674 94.5546 34.3241 95.3384L35.3047 95.1424C35.1509 94.3729 35.0134 93.5973 34.8926 92.8162L33.9043 92.969ZM35.4732 100.01C35.9268 101.557 36.4464 103.076 37.0287 104.562L37.9598 104.197C37.3882 102.738 36.8781 101.248 36.4328 99.7288L35.4732 100.01ZM38.9792 108.959C39.6959 110.399 40.474 111.803 41.3098 113.167L42.1624 112.644C41.3419 111.306 40.5781 109.928 39.8745 108.513L38.9792 108.959ZM44.0024 117.153C44.9595 118.444 45.9718 119.689 47.036 120.887L47.7834 120.222C46.7388 119.047 45.7451 117.824 44.8056 116.558L44.0024 117.153ZM50.3878 124.337C51.5556 125.437 52.7718 126.485 54.033 127.477L54.6512 126.691C53.4134 125.717 52.2197 124.689 51.0735 123.609L50.3878 124.337ZM57.9443 130.278C59.2871 131.151 60.671 131.966 62.0924 132.717L62.5596 131.833C61.165 131.096 59.8072 130.297 58.4895 129.44L57.9443 130.278ZM66.444 134.769C67.919 135.384 69.4272 135.934 70.9652 136.416L71.264 135.461C69.7554 134.989 68.2759 134.449 66.8288 133.846L66.444 134.769ZM75.6191 137.641C76.4011 137.81 77.1894 137.96 77.9836 138.093L78.1487 137.107C77.3696 136.976 76.5963 136.829 75.8292 136.664L75.6191 137.641Z"
      fill={stroke}
    />
    <path
      d="M139.817 93.1239C139.657 92.8985 139.345 92.8452 139.12 93.0048L135.448 95.6057C135.222 95.7653 135.169 96.0774 135.329 96.3027C135.488 96.5281 135.8 96.5814 136.026 96.4218L139.29 94.1099L141.602 97.3741C141.761 97.5994 142.073 97.6527 142.299 97.4931C142.524 97.3335 142.577 97.0214 142.418 96.7961L139.817 93.1239ZM93.7799 137.303C93.6155 137.525 93.6621 137.838 93.884 138.002L97.4998 140.681C97.7217 140.845 98.0348 140.799 98.1992 140.577C98.3636 140.355 98.317 140.042 98.0951 139.878L94.881 137.496L97.2622 134.282C97.4266 134.06 97.38 133.747 97.1581 133.583C96.9362 133.419 96.6231 133.465 96.4587 133.687L93.7799 137.303ZM94.2553 138.095C95.0612 137.975 95.8615 137.837 96.6555 137.683L96.4643 136.701C95.6848 136.853 94.8993 136.988 94.108 137.106L94.2553 138.095ZM101.388 136.538C102.955 136.084 104.493 135.562 105.998 134.974L105.635 134.043C104.157 134.619 102.648 135.132 101.11 135.578L101.388 136.538ZM110.449 133.004C111.907 132.279 113.327 131.491 114.707 130.643L114.184 129.791C112.829 130.623 111.435 131.397 110.004 132.108L110.449 133.004ZM118.737 127.912C120.04 126.941 121.299 125.914 122.508 124.834L121.842 124.088C120.655 125.149 119.419 126.157 118.14 127.11L118.737 127.912ZM125.992 121.433C127.101 120.249 128.158 119.016 129.159 117.737L128.372 117.121C127.389 118.376 126.351 119.587 125.262 120.749L125.992 121.433ZM131.985 113.773C132.866 112.413 133.687 111.012 134.446 109.572L133.561 109.106C132.817 110.519 132.01 111.895 131.146 113.229L131.985 113.773ZM136.52 105.168C137.142 103.676 137.699 102.15 138.189 100.594L137.235 100.294C136.755 101.821 136.207 103.319 135.597 104.783L136.52 105.168ZM139.438 95.888C139.61 95.0973 139.765 94.3001 139.902 93.497L138.916 93.3287C138.781 94.1169 138.63 94.8993 138.461 95.6754L139.438 95.888Z"
      fill={stroke}
    />
    <path
      d="M94.4051 32.1867C94.1826 32.3502 94.1347 32.6632 94.2983 32.8857L96.9631 36.5118C97.1267 36.7343 97.4396 36.7821 97.6621 36.6186C97.8847 36.4551 97.9325 36.1421 97.7689 35.9196L95.4002 32.6964L98.6234 30.3276C98.8459 30.1641 98.8937 29.8511 98.7302 29.6286C98.5666 29.4061 98.2537 29.3583 98.0312 29.5218L94.4051 32.1867ZM139.11 77.1779C139.331 77.3431 139.644 77.2975 139.809 77.0762L142.501 73.4696C142.666 73.2483 142.62 72.935 142.399 72.7698C142.177 72.6047 141.864 72.6502 141.699 72.8716L139.307 76.0774L136.101 73.6853C135.88 73.5202 135.566 73.5657 135.401 73.787C135.236 74.0083 135.282 74.3216 135.503 74.4868L139.11 77.1779ZM94.6257 33.0839C95.4136 33.2043 96.1958 33.3416 96.9719 33.4954L97.1663 32.5145C96.3758 32.3578 95.5792 32.2179 94.7767 32.0953L94.6257 33.0839ZM101.598 34.627C103.13 35.0746 104.633 35.5878 106.104 36.1635L106.469 35.2322C104.97 34.6459 103.439 34.123 101.878 33.6671L101.598 34.627ZM110.456 38.0937C111.882 38.8037 113.271 39.5747 114.619 40.4032L115.143 39.5512C113.77 38.7072 112.355 37.9218 110.902 37.1985L110.456 38.0937ZM118.561 43.0743C119.837 44.0245 121.067 45.0297 122.248 46.0862L122.915 45.3409C121.711 44.2644 120.458 43.2403 119.158 42.2723L118.561 43.0743ZM125.649 49.4165C126.733 50.5777 127.763 51.7869 128.736 53.0405L129.526 52.4274C128.534 51.1498 127.485 49.9175 126.381 48.7343L125.649 49.4165ZM131.479 56.9304C132.331 58.2665 133.123 59.6431 133.851 61.0565L134.74 60.599C133.998 59.1578 133.191 57.7544 132.322 56.3924L131.479 56.9304ZM135.827 65.3846C136.416 66.8516 136.937 68.351 137.388 69.8793L138.347 69.5965C137.887 68.0377 137.355 66.5084 136.755 65.0124L135.827 65.3846ZM138.516 74.5023C138.666 75.2786 138.799 76.061 138.914 76.8491L139.903 76.7053C139.787 75.9014 139.651 75.1034 139.497 74.3116L138.516 74.5023Z"
      fill={stroke}
    />
  </Base>
)

Neurodevelopment.propTypes = BaseProps

export default Neurodevelopment
