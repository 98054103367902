import React, { forwardRef } from "react"
import { renderClasses } from "utils/renderClasses"
import { BaseTypeProps } from "../props"
import "./heading1.scss"

const Heading1 = forwardRef(({ children, className, italic = false }, ref) => (
  <h1
    className={renderClasses("g-heading-tag g-heading1", [
      [className, className],
      [italic, "-i"],
      [true, "-m"],
    ])}
    ref={ref}
  >
    {children}
  </h1>
))

Heading1.props = BaseTypeProps

Heading1.displayName = "Heading1"

Heading1.propTypes = Heading1.props

export default Heading1
