import React, { forwardRef } from "react"
import { renderClasses } from "utils/renderClasses"
import { BaseTypeProps } from "../props"
import "./heading2.scss"

const Heading2 = forwardRef(({ children, className, italic = false }, ref) => (
  <h2
    className={renderClasses("g-heading-tag g-heading2", [
      [className, className],
      [italic, `-i`],
      [true, `-m`],
    ])}
    ref={ref}
  >
    {children}
  </h2>
))

Heading2.props = BaseTypeProps

Heading2.displayName = "Heading2"

Heading2.propTypes = Heading2.props

export default Heading2
