import React from "react"
import Base, { BaseProps } from "../../base"

const SpeechTherapy = ({ fill, stroke, ...props }) => (
  <Base
    {...props}
    className="g-speechTherapy"
    fill="none"
    height="172"
    viewBox="0 0 172 172"
    width="172"
  >
    <circle cx="86" cy="86" fill={fill} r="86" />
    <path
      d="M76.4726 102.558L64.5818 114.218C64.1982 114.602 63.6612 114.295 63.6612 113.835V99.796C63.6612 99.4891 63.4311 99.259 63.1242 99.259H61.8968H49.6991C45.7867 99.259 42.6414 96.1137 42.6414 92.2012V61.5153C42.6414 57.6028 45.7867 54.4575 49.6991 54.4575H101.942C105.854 54.4575 109 57.6028 109 61.5153V79.6242"
      stroke={stroke}
      strokeLinecap="round"
    />
    <path
      d="M91.1137 122.967C86.2806 122.967 82.3682 119.054 82.3682 114.221V93.1248C82.3682 88.2917 86.2806 84.3793 91.1137 84.3793H118.961C123.794 84.3793 127.707 88.2917 127.707 93.1248V114.145C127.707 118.978 123.794 122.89 118.961 122.89H114.051C113.745 122.89 113.514 123.12 113.514 123.427V133.477C113.514 133.937 112.977 134.167 112.594 133.86L100.703 123.044C100.626 122.967 100.473 122.89 100.319 122.89H91.1137V122.967Z"
      stroke={stroke}
      strokeLinecap="round"
    />
    <path
      d="M129.778 82.9982L139.137 73.7157"
      stroke={stroke}
      strokeLinecap="round"
    />
    <path d="M125.865 80.8502V68.7292" stroke={stroke} strokeLinecap="round" />
    <path d="M132.921 86.067H145.042" stroke={stroke} strokeLinecap="round" />
    <path d="M40.3592 50.7825L31 41.5" stroke={stroke} strokeLinecap="round" />
    <path d="M39.1795 54.3042H27.0586" stroke={stroke} strokeLinecap="round" />
    <path d="M43.3984 49.2464V37.1255" stroke={stroke} strokeLinecap="round" />
    <circle cx="64.9036" cy="77.2822" fill={stroke} r="3.33918" />
    <circle cx="76.0342" cy="77.2822" fill={stroke} r="3.33918" />
    <circle cx="87.1649" cy="77.2822" fill={stroke} r="3.33918" />
    <circle cx="92.7286" cy="105.108" fill={stroke} r="3.33918" />
    <circle cx="104.972" cy="105.108" fill={stroke} r="3.33918" />
    <circle cx="117.216" cy="105.108" fill={stroke} r="3.33918" />
  </Base>
)

SpeechTherapy.propTypes = BaseProps

export default SpeechTherapy
