export const CORTICA_CAREERS_IFRAME =
  "https://cortica-marketing.azurewebsites.net/corticacareers/public/"

export const googleMapURL = `https://maps.googleapis.com/maps/api/js?libraries=geometry,drawing,places&key=${process.env.GATSBY_G_CLOUD_API}`

export const COLORS = {
  black: "#1e1e1e",
  blue: "#6987ca",
  corticaBlue: "#10389a",
  darkBlue: "#122f4e",
  darkGray: "#e5e5e5",
  darkGreen: "#2a5c5d",
  darkYellow: "#f4b446",
  green: "#679381",
  ivory: "#f9f8f2",
  lightGray: "#f9f9f9",
  lightText: "#808080",
  mediumGray: "#e8e8e8",
  pastelBlue: "#e1efff",
  pastelGreen: "#d8f4e2",
  pastelPink: "#ffe7e5",
  pastelYellow: "#ffebca",
  pink: "#e4bab7",
  white: "#ffffff",
  yellow: "#f8d185",
}

/**
 * Motion Principles
 *
 * Curves
 * Having various motion curves allows for a dynamic system that takes into
 * account the wide range of interactions and expressions that the user will
 * experience throughout their journey. Below is a list of motion curves used
 * throughout the site, along with example flows with module motion breakdowns
 * within the following pages. The high level motion principles can be used
 * to fill any motion needs not laid out within the examples.
 *
 * Speeds
 * Having multiple speeds creates flexibility within the site’s motion that
 * can account for the wide range of the user’s needs and accounts for a
 * range of utility, from reactive to expressive. Below are a list of speeds
 * used within the system, along with when and how to use them. There are also
 * examples on the following pages to show how they are used throughout various
 * modules.
 */

export const MOTION = {
  curves: {
    /**
     * Curve A
     * This linear motion should only be used on elements that are constant.
     * The main example of this is illustrations with rotating elements.
     */
    a: "0.00, 0.00, 1.00, 1.00",
    /**
     * Curve B
     * This curve is primarily used for revealing elements. This includes
     * load-ins, revealing hover states, navigation menu opening, and
     * revealing of buttons.
     */
    b: "0.00, 0.00, 0.20, 1.00",
    /**
     * Curve C
     * The primary use of Curve C is disappearing elements, such as buttons
     * disappearing, the ending of a hover state, and closing the navigation
     * menu.
     */
    c: "0.80, 0.00, 1.00, 1.00",
    /**
     * Curve D
     * Curve D is generally for transitioning elements that are always visible.
     * One example of this is arrow rotation on click and the closing / opening
     * of dropdown menus.
     */
    d: "0.40, 0.00, 0.25, 1.00",
  },
  speeds: {
    /**
     * Speed 1
     * This speed will be primarily used for motion that is a direct response
     * to a user’s interaction with an element. This allows for quick feedback
     * for the user. Examples includes hover states and menus opening / closing.
     */
    fast: "165ms",
    /**
     * Speed 2
     * This duration is used for secondary motion, generally during a transition
     * of states. The main examples of this the speed are scrims, color transitions,
     * card movements, and carousel transitions.
     */
    medium: "500ms",
    /**
     * Speed 3
     * The primary use of this speed is for loading in information. This gives the
     * site its expressiveness and a personalized experience for the user.
     */
    slow: "830ms",
  },
}

export const DEVICE = {
  desktop: 1440,
  desktopLarge: 1920,
  mobile: 375,
  mobileLandscape: 667,
  tablet: 768,
  tabletLandscape: 1024,
}
