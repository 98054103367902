import React from "react"
import Base, { BaseProps } from "../../base"

const DevelopmentalTherapy = ({ fill, stroke, ...props }) => (
  <Base
    {...props}
    className="g-developmentalTherapy"
    fill="none"
    height="172"
    viewBox="0 0 172 172"
    width="172"
  >
    <circle cx="86" cy="86" fill={fill} r="86" />
    <rect
      fill={fill}
      height="70.0128"
      rx="7.5"
      stroke={stroke}
      transform="rotate(-15 34.6124 48.5867)"
      width="50.1292"
      x="34.6124"
      y="48.5867"
    />
    <circle
      cx="67.9761"
      cy="75.8021"
      fill={fill}
      r="17.9633"
      stroke={stroke}
      strokeDasharray="2 2"
      transform="rotate(-15 67.9761 75.8021)"
    />
    <path
      d="M61.8286 85.7817C60.6109 81.2371 63.3096 76.5627 67.8542 75.345C72.3988 74.1273 77.0732 76.826 78.2909 81.3706"
      fill={fill}
    />
    <path
      d="M61.8286 85.7817C60.6109 81.2371 63.3096 76.5627 67.8542 75.345C72.3988 74.1273 77.0732 76.826 78.2909 81.3706"
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <rect
      fill={fill}
      height="70.0128"
      rx="7.5"
      stroke={stroke}
      width="50.1292"
      x="75.5859"
      y="66.8386"
    />
    <circle
      cx="100.651"
      cy="101.845"
      fill={fill}
      r="17.9633"
      stroke={stroke}
      strokeDasharray="2 2"
    />
    <path
      d="M111 103C111 108.521 106.306 113 100.52 113C94.7341 113 90 108.521 90 103H111Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
    />
  </Base>
)

DevelopmentalTherapy.propTypes = BaseProps

export default DevelopmentalTherapy
