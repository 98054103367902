import React from "react"
import Base, { BaseProps } from "../base"

const Pause = ({ fill, stroke, strokeWidth, ...props }) => (
  <Base
    {...props}
    className="g-pause"
    fill={fill}
    height="42"
    viewBox="0 0 42 42"
    width="42"
  >
    <path
      d="M21 41C32.0457 41 41 32.0457 41 21C41 9.9543 32.0457 1 21 1C9.95431 1 1 9.9543 1 21C1 32.0457 9.95431 41 21 41Z"
      fill={fill}
      stroke={stroke}
      strokeMiterlimit="10"
      strokeWidth={strokeWidth}
    />
    <line
      stroke={stroke}
      strokeLinecap="round"
      strokeWidth={strokeWidth}
      x1="16.5"
      x2="16.5"
      y1="14.5"
      y2="27.5"
    />
    <line
      stroke={stroke}
      strokeLinecap="round"
      strokeWidth={strokeWidth}
      x1="25.5"
      x2="25.5"
      y1="14.5"
      y2="27.5"
    />
  </Base>
)

Pause.propTypes = BaseProps

export default Pause
