import React, { useState } from "react"
import PropTypes from "prop-types"
import { renderClasses } from "utils/renderClasses"
import "./toolTip.scss"

const ToolTip = ({ color = "blue", expandedText, text }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)

  const handleKeyPress = e => {
    const code = e.charCode || e.keyCode
    if (code === 32 || code === 13) {
      toggle()
    }
  }

  return (
    <>
      <span
        aria-checked={toggle}
        className={renderClasses(
          `g-tooltip__summary g-tooltip__summary--${color}`,
          [[isOpen, "g-tooltip__summary--open"]]
        )}
        onClick={toggle}
        onKeyPress={handleKeyPress}
        role="switch"
        tabIndex="0"
      >
        {text}
      </span>
      <span
        className={renderClasses(`g-tooltip__def g-tooltip__def--${color}`, [
          [isOpen, "g-tooltip__def--open"],
        ])}
      >
        {expandedText}
      </span>
    </>
  )
}

ToolTip.props = {
  color: PropTypes.oneOf(["blue", "green", "yellow", "pink", "teal"]),
  expandedText: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

ToolTip.propTypes = ToolTip.props

export default ToolTip
