import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Icon from "components/elements/Icon"
import "./links.scss"
import BhcoeIcon from "./BhcoeAccredidation.webp"
import GreatWorkBadge from "./GreatPlaceToWork.webp"

const Links = ({ utilityLinks }) => {
  const year = new Date().getFullYear()

  return (
    <>
      <div className="g-footer-copyright-links">
        <p>&copy; {year} Cortica</p>
        {utilityLinks.map((link, i) => (
          <Link key={i} to={link.path}>
            {link.text}
          </Link>
        ))}
      </div>
      <div className="g-footer-badges">
        <div className="gptw-icon">
          <img alt="work icon" src={GreatWorkBadge} />
        </div>
        <div className="bhcoe-icon">
          <img alt="bhcoe icon" src={BhcoeIcon} />
        </div>
        <div className="g-footer-lgbt-icon">
          <Icon icon="Lgbtq" />
        </div>
      </div>
    </>
  )
}

Links.props = {
  utilityLinks: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      text: PropTypes.string,
    })
  ),
}

Links.propTypes = Links.props

export default Links
