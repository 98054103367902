import React from "react"
import Carousel from "react-bootstrap/Carousel"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import PropTypes from "prop-types"

export const AnnouncementCarousel = ({ announcement }) => {
  return (
    <Carousel className="left" controls={false} fade={true} indicators={false}>
      {announcement.json.content.map((announcementContent, index) => (
        <Carousel.Item className="carousel-item" key={index}>
          {documentToReactComponents({
            nodeType: "document",
            content: [announcementContent],
            data: {},
          })}
        </Carousel.Item>
      ))}
    </Carousel>
  )
}
AnnouncementCarousel.propTypes = {
  announcement: PropTypes.shape({
    json: PropTypes.shape({
      content: PropTypes.arrayOf(PropTypes.object),
    }),
  }),
}
AnnouncementCarousel.propTypes = AnnouncementCarousel.props
