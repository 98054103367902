import React from "react"
import Base, { BaseProps } from "../../base"

const Medical = ({ fill, stroke, ...props }) => (
  <Base
    {...props}
    className="g-medical"
    fill="none"
    height="172"
    viewBox="0 0 172 172"
    width="172"
  >
    <circle cx="86" cy="86" fill={fill} r="86" />
    <circle cx="86" cy="86" fill={fill} r="86" />
    <path
      d="M58.0007 46.3038H55.7007C53.4007 46.3038 51.5007 44.4038 51.6007 42.1038C51.6007 39.8038 53.5007 37.9038 55.8007 38.0038H58.1007C60.4007 38.0038 62.3007 39.9038 62.2007 42.2038C62.2007 44.4038 60.3007 46.3038 58.0007 46.3038Z"
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M79.4005 46.4038H81.7005C84.0005 46.4038 85.9005 44.6038 85.9005 42.3038C85.9005 40.0038 84.1005 38.1038 81.8005 38.1038H79.5005C77.2005 38.1038 75.3005 39.9038 75.3005 42.2038C75.3005 44.5038 77.1005 46.4038 79.4005 46.4038Z"
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M85.9006 42.2038C89.9006 42.2038 98.3006 45.7038 98.2006 62.7038C98.1006 77.4038 85.5006 103.904 68.3006 103.704C51.1006 103.504 38.9006 77.0038 39.0006 62.3038C39.1006 45.2038 47.6006 41.9038 51.6006 42.0038"
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M68.3007 103.704C68.3007 103.704 65.9007 129.604 81.2007 137.204C102.401 147.804 110.201 124.604 110.601 115.304C110.901 109.004 106.601 101.504 96.9007 103.404C85.4007 105.604 84.3007 127.904 107.001 128.404C131.301 128.904 127.501 80.2037 127.501 80.2037"
      stroke={stroke}
      strokeDasharray="4 4"
      strokeMiterlimit="10"
    />
    <path
      d="M127.601 79.9038C129.644 79.9038 131.301 78.2472 131.301 76.2038C131.301 74.1603 129.644 72.5038 127.601 72.5038C125.557 72.5038 123.901 74.1603 123.901 76.2038C123.901 78.2472 125.557 79.9038 127.601 79.9038Z"
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M122.201 86.1038C118.701 84.2038 116.301 80.4038 116.301 76.1038C116.301 69.9038 121.401 64.9038 127.601 64.9038C133.801 64.9038 138.801 70.0038 138.801 76.2038C138.801 80.4038 136.401 84.1038 132.901 86.0038"
      stroke={stroke}
      strokeMiterlimit="10"
    />
  </Base>
)

Medical.propTypes = BaseProps

export default Medical
