import React from "react"
import Base, { BaseProps } from "../../base"

const Appointment = ({ fill, stroke, ...props }) => (
  <Base
    {...props}
    className="g-appointment"
    fill="none"
    height="172"
    viewBox="0 0 172 172"
    width="172"
  >
    <circle cx="86" cy="86" fill={fill} r="86" />
    <circle cx="86" cy="86" fill={fill} r="86" />
    <path
      d="M61.0008 75.9C61.0008 77.6121 59.6129 79 57.9008 79C56.1887 79 54.8008 77.6121 54.8008 75.9C54.8008 74.188 56.1887 72.8 57.9008 72.8C59.6129 72.8 61.0008 74.188 61.0008 75.9Z"
      fill={stroke}
      stroke={stroke}
    />
    <path
      d="M79.6009 75.9C79.6009 77.6121 78.213 79 76.5009 79C74.7888 79 73.4009 77.6121 73.4009 75.9C73.4009 74.188 74.7888 72.8 76.5009 72.8C78.213 72.8 79.6009 74.188 79.6009 75.9Z"
      fill={stroke}
      stroke={stroke}
    />
    <path
      d="M98.3008 75.9C98.3008 77.6121 96.9129 79 95.2008 79C93.4887 79 92.1008 77.6121 92.1008 75.9C92.1008 74.188 93.4887 72.8 95.2008 72.8C96.9129 72.8 98.3008 74.188 98.3008 75.9Z"
      fill={stroke}
      stroke={stroke}
    />
    <path
      d="M116.901 75.9C116.901 77.6121 115.513 79 113.801 79C112.089 79 110.701 77.6121 110.701 75.9C110.701 74.188 112.089 72.8 113.801 72.8C115.513 72.8 116.901 74.188 116.901 75.9Z"
      fill={stroke}
      stroke={stroke}
    />
    <path
      d="M61.0008 91.6C61.0008 93.3121 59.6129 94.7 57.9008 94.7C56.1887 94.7 54.8008 93.3121 54.8008 91.6C54.8008 89.8879 56.1887 88.5 57.9008 88.5C59.6129 88.5 61.0008 89.8879 61.0008 91.6Z"
      fill={stroke}
      stroke={stroke}
    />
    <path
      d="M98.3008 91.6C98.3008 93.3121 96.9129 94.7 95.2008 94.7C93.4887 94.7 92.1008 93.3121 92.1008 91.6C92.1008 89.8879 93.4887 88.5 95.2008 88.5C96.9129 88.5 98.3008 89.8879 98.3008 91.6Z"
      fill={stroke}
      stroke={stroke}
    />
    <path
      d="M116.901 91.6C116.901 93.3121 115.513 94.7 113.801 94.7C112.089 94.7 110.701 93.3121 110.701 91.6C110.701 89.8879 112.089 88.5 113.801 88.5C115.513 88.5 116.901 89.8879 116.901 91.6Z"
      fill={stroke}
      stroke={stroke}
    />
    <path
      d="M61.0008 107.3C61.0008 109.012 59.6129 110.4 57.9008 110.4C56.1887 110.4 54.8008 109.012 54.8008 107.3C54.8008 105.588 56.1887 104.2 57.9008 104.2C59.6129 104.2 61.0008 105.588 61.0008 107.3Z"
      fill={stroke}
      stroke={stroke}
    />
    <path
      d="M79.6009 107.3C79.6009 109.012 78.213 110.4 76.5009 110.4C74.7888 110.4 73.4009 109.012 73.4009 107.3C73.4009 105.588 74.7888 104.2 76.5009 104.2C78.213 104.2 79.6009 105.588 79.6009 107.3Z"
      fill={stroke}
      stroke={stroke}
    />
    <path
      d="M98.3008 107.3C98.3008 109.012 96.9129 110.4 95.2008 110.4C93.4887 110.4 92.1008 109.012 92.1008 107.3C92.1008 105.588 93.4887 104.2 95.2008 104.2C96.9129 104.2 98.3008 105.588 98.3008 107.3Z"
      fill={stroke}
      stroke={stroke}
    />
    <path
      d="M116.901 107.3C116.901 109.012 115.513 110.4 113.801 110.4C112.089 110.4 110.701 109.012 110.701 107.3C110.701 105.588 112.089 104.2 113.801 104.2C115.513 104.2 116.901 105.588 116.901 107.3Z"
      fill={stroke}
      stroke={stroke}
    />
    <path
      d="M66 62.5674C64.3421 62.5674 63 61.1327 63 59.3603V52.4398C63 50.6674 64.3421 49.2327 66 49.2327C67.6579 49.2327 69 50.6674 69 52.4398V59.3603C69 61.1327 67.6579 62.5674 66 62.5674Z"
      fill={stroke}
    />
    <path
      d="M86.1563 62.5674C84.4984 62.5674 83.1562 61.1327 83.1562 59.3603V52.4398C83.1562 50.6674 84.4984 49.2327 86.1563 49.2327C87.8141 49.2327 89.1562 50.6674 89.1562 52.4398V59.3603C89.1562 61.1327 87.8141 62.5674 86.1563 62.5674Z"
      fill={stroke}
    />
    <path
      d="M104 62.5674C102.342 62.5674 101 61.1327 101 59.3603V52.4398C101 50.6674 102.342 49.2327 104 49.2327C105.658 49.2327 107 50.6674 107 52.4398V59.3603C107 61.1327 105.658 62.5674 104 62.5674Z"
      fill={stroke}
    />
    <path
      d="M83.6654 86L74.4987 95.1667L70.332 91"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M124.5 123.4H47.3C44.9 123.4 43 121.5 43 119.1V60.3C43 57.9 44.9 56 47.3 56H124.5C126.9 56 128.8 57.9 128.8 60.3V119.2C128.7 121.5 126.8 123.4 124.5 123.4Z"
      stroke={stroke}
      strokeMiterlimit="10"
    />
  </Base>
)

Appointment.propTypes = BaseProps

export default Appointment
