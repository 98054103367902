import React from "react"
import PropTypes from "prop-types"
import Col from "../../Col"
import "./social.scss"

const Social = ({ socialLinks }) => (
  <div className="g-footer-social">
    <Col label="Follow Us">
      <ul>
        {socialLinks.map((link, i) => (
          <li key={i}>
            <a href={link.path}>{link.text}</a>
          </li>
        ))}
      </ul>
    </Col>
  </div>
)

Social.props = {
  socialLinks: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      text: PropTypes.string,
    })
  ),
}

Social.propTypes = Social.props

export default Social
