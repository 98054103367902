import React from "react"
import Base, { BaseProps } from "../base"

const Lgbtq = ({ fill, stroke, strokeWidth, ...props }) => (
  <Base
    {...props}
    className="g-lgbtq"
    fill="none"
    height="35"
    viewBox="0 0 40 35"
    width="40"
  >
    <g clipPath="url(#clip0_1498_10955)">
      <path
        d="M17.1403 29.6942L20 34.6403L22.8597 29.6942H17.1403Z"
        fill="#714399"
      />
      <path
        d="M17.1402 29.6941H22.8597L25.7194 24.748H14.2805L17.1402 29.6941Z"
        fill="#4A3091"
      />
      <path
        d="M14.2986 24.7478H25.7195L28.5792 19.8018H11.4209L14.2986 24.7478Z"
        fill="#26408D"
      />
      <path
        d="M8.5791 14.8379L9.99996 17.3199L11.4388 19.8019H28.5791L30 17.3199L31.4388 14.8379H8.5791Z"
        fill="#3FAB49"
      />
      <path
        d="M8.57919 14.8556H31.4389L34.2986 9.8916H5.71948L8.57919 14.8556Z"
        fill="#FEEF00"
      />
      <path
        d="M5.71933 9.91032H34.2985L37.1582 4.94629H2.85962L5.71933 9.91032Z"
        fill="#F68E1E"
      />
      <path
        d="M37.1403 4.96403L40 0H20H0L2.85971 4.96403H37.1403Z"
        fill="#D0252C"
      />
    </g>
    <defs>
      <clipPath id="clip0_1498_10955">
        <rect fill="white" height="34.6403" width="40" />
      </clipPath>
    </defs>
  </Base>
)

Lgbtq.propTypes = BaseProps

export default Lgbtq
