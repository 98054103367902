import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import "./links.scss"

const Links = ({ links }) => (
  <ul className="g-footer-menu-links">
    {links.map((link, i) => (
      <li key={i}>
        <Link to={link.path}>{link.text}</Link>
      </li>
    ))}
  </ul>
)

Links.props = {
  label: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
}

Links.propTypes = Links.props

export default Links
