import React from "react"
import PropTypes from "prop-types"
import { renderClasses } from "utils/renderClasses"

const Base = ({ children, className, inverted = false, ...props }) => (
  <svg
    {...props}
    className={renderClasses("", [
      [className, className],
      [inverted, "--inverted"],
    ])}
    xmlns="http://www.w3.org/2000/svg"
  >
    {children}
  </svg>
)

export const BaseProps = {
  children: PropTypes.node,
  className: PropTypes.string,
  fill: PropTypes.string,
  inverted: PropTypes.bool,
  stroke: PropTypes.string,
  strokeWidth: PropTypes.string,
  viewBox: PropTypes.string,
}

Base.propTypes = BaseProps

export default Base
