import React from "react"
import PropTypes from "prop-types"
import Schedule from "./Schedule"
import Social from "./Social"
import "./actions.scss"

const Actions = ({ scheduleAppointment, socialLinks }) => (
  <div className="g-footer-actions">
    <div className="g-footer-actions-group">
      <Schedule {...scheduleAppointment} />
      <Social socialLinks={socialLinks} />
    </div>
  </div>
)

Actions.props = {
  ...Social.props,
  scheduleAppointment: PropTypes.shape(Schedule.props),
}

Actions.propTypes = Actions.props

export default Actions
