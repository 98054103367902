import React from "react"
import PropTypes from "prop-types"
import { renderClasses } from "utils/renderClasses"
import Button from "components/elements/styledButton"
import UtilityNav from "../../UtilityNav"
import Links from "./Links"
import "./menu.scss"

const Menu = ({ menuOpen, menus, utilityLinks }) => (
  <div
    className={renderClasses("g-header-top-nav-menu", [[menuOpen, "--open"]])}
  >
    <div>
      <Links menuOpen={menuOpen} menus={menus} />
      <div className="g-header-top-nav-menu-cta">
        {utilityLinks[0] && (
          <Button
            className="g-header-top-nav-menu-cta-button"
            color={["ivory"]}
            href={utilityLinks[0].path}
            isOutlined
            text={utilityLinks[0].text}
          />
        )}
        {utilityLinks[1] && (
          <a
            className="g-header-top-nav-menu-cta-inline"
            href={utilityLinks[1].path}
          >
            {utilityLinks[1].text}
          </a>
        )}
      </div>
    </div>
  </div>
)

Menu.props = {
  ...Links.props,
  ...UtilityNav.props,
}

Menu.propTypes = {
  ...Menu.props,
  menuOpen: PropTypes.bool.isRequired,
}

export default Menu
