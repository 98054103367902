import React from "react"
import PropTypes from "prop-types"
import { renderClasses } from "utils/renderClasses"
import { iconTheme } from "utils/iconTheme"
import { matchIcon } from "utils/matchIcon"
import { BaseProps } from "components/icons/base"
import "./icon.scss"

const Icon = ({
  className,
  fill,
  icon,
  inverted = false,
  stroke,
  theme,
  ...props
}) => {
  const { fill: iconFill, stroke: iconStroke } = iconTheme(theme, {
    fill,
    stroke,
  })

  return (
    <div
      className={renderClasses("g-icon", [
        [className, className],
        [inverted, "--inverted"],
      ])}
    >
      {matchIcon(icon)({
        ...props,
        fill: iconFill,
        inverted,
        stroke: iconStroke,
      })}
    </div>
  )
}

Icon.props = {
  ...BaseProps,
  icon: PropTypes.oneOf([
    "Appointment",
    "Arrow",
    "BehavioralTherapy",
    "Caret",
    "CloseBtn",
    "ComprehensiveCare",
    "DevelopmentalTherapy",
    "Family",
    "Integrated",
    "Lgbtq",
    "Logo",
    "Medical",
    "MedicalSecondary",
    "Minus",
    "Neurodevelopment",
    "Nutrition",
    "Pause",
    "Play",
    "Plus",
    "Personalized",
    "Progress",
    "Research",
    "ResearchSecondary",
    "SensoryMotor",
    "SpeechTherapy",
    "Telehealth",
    "WholeBody",
  ]).isRequired,
  inverted: PropTypes.bool,
  theme: PropTypes.oneOf(["DarkBlue", "Pink", "Green", "Blue", "Yellow"]),
}

Icon.propTypes = Icon.props

export default Icon
