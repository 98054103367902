import React from "react"
import PropTypes from "prop-types"
import Wysiwyg from "components/type/Wysiwyg"
import "./utilitynav.scss"
import { AnnouncementCarousel } from "./AnnouncementCarousel"

const UtilityNav = ({ announcement, utilityLinks }) => (
  <div className="g-header-utility-nav">
    <div className="max-container">
      {announcement && <AnnouncementCarousel announcement={announcement} />}
      {utilityLinks && (
        <ul className="right">
          {utilityLinks.map((link, i) => (
            <li key={i}>
              <a href={link.path}>{link.text}</a>
            </li>
          ))}
        </ul>
      )}
    </div>
  </div>
)

UtilityNav.props = {
  announcement: PropTypes.shape(Wysiwyg.props),
  utilityLinks: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ),
}

UtilityNav.propTypes = UtilityNav.props

export default UtilityNav
