import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"
import { Arrow } from "components/icons"
import "bootstrap/dist/css/bootstrap.css"
import { renderClasses } from "utils/renderClasses"
import "./button.scss"
import { useLocation } from "@reach/router"
import queryString from "query-string"

const Button = ({
  accessibilityLabel,
  autoLaunch = false,
  className,
  color = ["blue"],
  hasArrow = false,
  href,
  isDisabled = false,
  isOutlined = false,
  isSmall = false,
  onClick,
  text,
  ...props
}) => {
  const classPrefix = "styled-btn"

  const content = (
    <>
      <span className="styled-btn__content">
        {text}
        {hasArrow && (
          <Arrow
            direction="down"
            fill="transparent"
            fillSecondary="currentColor"
            role="presentation"
          />
        )}
      </span>
      <span className="styled-btn__bg" role="presentation" />
    </>
  )

  const location = useLocation()

  // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    const qs = location.search ? queryString.parse(location.search) : null
    if (autoLaunch && qs?.selfRegister === "true") {
      console.log(qs)
    }
  }, [location, autoLaunch])

  const continueBtn = () => {
    navigate("/welcomevisit")
  }

  return (
    <>
      <div>
        {href === "/make-an-appointment" ? (
          <button
            {...props}
            aria-label={accessibilityLabel}
            className={renderClasses(`styled-btn ${classPrefix}--${color[0]}`, [
              [className, className],
              [isOutlined, `${classPrefix}--outlined`],
              [isSmall, `${classPrefix}--small`],
            ])}
            onClick={continueBtn}
          >
            {content}
          </button>
        ) : href && !isDisabled ? (
          <a
            {...props}
            aria-label={accessibilityLabel}
            className={renderClasses(`styled-btn ${classPrefix}--${color}`, [
              [className, className],
              [isOutlined, `${classPrefix}--outlined`],
              [isSmall, `${classPrefix}--small`],
            ])}
            href={href}
          >
            {content}
          </a>
        ) : (
          <button
            {...props}
            aria-label={accessibilityLabel}
            className={renderClasses(`styled-btn ${classPrefix}--${color[0]}`, [
              [className, className],
              [isOutlined, `${classPrefix}--outlined`],
              [isSmall, `${classPrefix}--small`],
            ])}
            disabled={isDisabled || !onClick}
            onClick={onClick}
          >
            {content}
          </button>
        )}
      </div>
    </>
  )
}

Button.props = {
  accessibilityLabel: PropTypes.string,
  className: PropTypes.string,
  /**
   * Sets the color style of the button
   */
  color: PropTypes.arrayOf(
    PropTypes.oneOf(["blue", "ivory", "yellow", "yellow-mobile"])
  ),
  /**
   * Adds a down arrow icon to the right of the text
   */
  hasArrow: PropTypes.bool,
  /**
   * If provided, the button renders as an `<a>` tag and functions like a link
   */
  href: PropTypes.string,
  /**
   * When `true`, the button is in a disabled, un-interactive state
   */
  isDisabled: PropTypes.bool,
  /**
   * Uses a secondary button style with an outline
   */
  isOutlined: PropTypes.bool,
  /**
   * Renders a smaller style of the button
   */
  isSmall: PropTypes.bool,
  /**
   * When present, the button renders as a `<button>` element and runs the
   * provided function on click
   */
  onClick: PropTypes.func,
  /**
   * The text inside the button
   */
  text: PropTypes.string.isRequired,
}

Button.propTypes = Button.props

export default Button
