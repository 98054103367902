import React from "react"
import PropTypes from "prop-types"
import Col from "../Col"
import Links from "./Links"
import "./menu.scss"

const Menu = ({ menus }) => (
  <div className="g-footer-menu">
    <div className="g-footer-menu-groups">
      {menus.map((group, i) => (
        <Col {...group} key={i}>
          <Links {...group} />
        </Col>
      ))}
    </div>
  </div>
)

Menu.props = {
  menus: PropTypes.arrayOf(PropTypes.shape(Links.props)).isRequired,
}

Menu.propTypes = Menu.props

export default Menu
