import React from "react"
import PropTypes from "prop-types"
import { renderClasses } from "utils/renderClasses"
import Button from "components/elements/styledButton"
import "./action.scss"

const Action = ({ href, menuOpen, menuToggleHandler, text }) => (
  <div className="g-header-top-nav-action">
    <Button
      autoLaunch
      className="g-header-top-nav-action-cta"
      color={menuOpen ? ["yellow-mobile"] : ["yellow"]}
      href={href}
      isSmall
      text={text}
    />
    <button
      aria-label={menuOpen ? "Close menu" : "Open menu"}
      className={renderClasses("g-header-top-nav-action-hamburger", [
        [menuOpen, "--open"],
      ])}
      onClick={menuToggleHandler}
    >
      <span>{menuOpen ? "Close" : "Open"}</span>
    </button>
  </div>
)

Action.propTypes = {
  ...Button.props,
  menuOpen: PropTypes.bool.isRequired,
  menuToggleHandler: PropTypes.func.isRequired,
}

export default Action
