import React from "react"
import Base, { BaseProps } from "../../base"

const Integrated = ({ fill, stroke, ...props }) => (
  <Base
    {...props}
    className="g-integrated"
    fill="none"
    height="172"
    viewBox="0 0 172 172"
    width="172"
  >
    <circle cx="86" cy="86" fill={fill} r="86" />
    <g clipPath="url(#clip0_1016_8179)">
      <path
        d="M69.9008 62C70.2008 62.1 70.4008 61.9 70.5008 61.6L71.7008 57.2999C71.8008 57 71.6008 56.8 71.3008 56.7C71.0008 56.6 70.8008 56.8 70.7008 57.1L69.7008 61L65.8008 60C65.5008 59.9 65.3008 60.1 65.2008 60.4C65.1008 60.6999 65.3008 60.9 65.6008 61L69.9008 62ZM54.3008 35.2L55.3008 36.9L56.2008 36.4L55.2008 34.7L54.3008 35.2ZM57.1008 40.2L59.0008 43.5L59.9008 43L58.0008 39.7L57.1008 40.2ZM61.0008 46.8L62.9008 50.1L63.8008 49.6L61.9008 46.3L61.0008 46.8ZM64.8008 53.5L66.7008 56.7999L67.6008 56.2999L65.7008 53L64.8008 53.5Z"
        fill={stroke}
      />
      <path
        d="M100.1 61C100.2 61.3 100.4 61.4 100.7 61.4L105 60.2C105.3 60.1 105.4 59.9 105.4 59.6C105.3 59.3 105.1 59.2 104.8 59.2L100.9 60.2L99.9004 56.3C99.8004 56 99.6004 55.9 99.3004 55.9C99.0004 56 98.9004 56.2 98.9004 56.5L100.1 61ZM115.5 34L114.5 35.7L115.4 36.2L116.4 34.5L115.5 34ZM112.6 39L110.7 42.3L111.6 42.8L113.5 39.5L112.6 39ZM108.8 45.6L106.9 49L107.8 49.5L109.7 46.2L108.8 45.6ZM105 52.3L103 55.6L103.9 56.1L105.8 52.8L105 52.3Z"
        fill={stroke}
      />
      <path
        d="M100.699 110.4C100.399 110.3 100.199 110.5 100.099 110.8L98.8991 115.1C98.7991 115.4 98.9991 115.6 99.2991 115.7C99.5991 115.8 99.7991 115.6 99.8991 115.3L100.899 111.4L104.799 112.4C105.099 112.5 105.299 112.3 105.399 112C105.499 111.7 105.299 111.5 104.999 111.4L100.699 110.4ZM116.399 137.3L115.399 135.6L114.499 136.1L115.499 137.8L116.399 137.3ZM113.499 132.3L111.599 129L110.699 129.5L112.599 132.8L113.499 132.3ZM109.699 125.6L107.799 122.3L106.899 122.8L108.799 126.1L109.699 125.6ZM105.799 119L103.899 115.7L102.999 116.2L104.899 119.5L105.799 119Z"
        fill={stroke}
      />
      <path
        d="M70.4992 111.5C70.3992 111.2 70.1992 111.1 69.8992 111.1L65.5992 112.3C65.2992 112.4 65.1992 112.6 65.1992 112.9C65.2992 113.2 65.4992 113.3 65.7992 113.3L69.6992 112.3L70.6992 116.2C70.7992 116.5 70.9992 116.6 71.2992 116.6C71.5992 116.5 71.6992 116.3 71.6992 116L70.4992 111.5ZM55.0992 138.5L56.0992 136.8L55.1992 136.3L54.1992 138L55.0992 138.5ZM57.9992 133.5L59.8992 130.2L58.9992 129.7L57.0992 133L57.9992 133.5ZM61.7992 126.8L63.6992 123.5L62.8992 123L60.9992 126.3L61.7992 126.8ZM65.6992 120.2L67.5992 116.9L66.6992 116.4L64.7992 119.7L65.6992 120.2Z"
        fill={stroke}
      />
      <path
        d="M112.8 84.9C112.6 85.1 112.6 85.4 112.8 85.6L116 88.8C116.2 89 116.5 89 116.7 88.8C116.9 88.6 116.9 88.3 116.7 88.1L113.9 85.3L116.7 82.5C116.9 82.3 116.9 82 116.7 81.8C116.5 81.6 116.2 81.6 116 81.8L112.8 84.9ZM144 84.7H142V85.7H143.9V84.7H144ZM138.2 84.7H134.4V85.7H138.2V84.7ZM130.5 84.7H126.7V85.7H130.5V84.7ZM122.8 84.7H119V85.7H122.8V84.7Z"
        fill={stroke}
      />
      <path
        d="M58.1 85.6C58.3 85.4 58.3 85.1 58.1 84.9L54.9 81.7C54.7 81.5 54.4 81.5 54.2 81.7C54 81.9 54 82.2 54.2 82.4L57 85.2L54.2 88C54 88.2 54 88.5 54.2 88.7C54.4 88.9 54.7 88.9 54.9 88.7L58.1 85.6ZM28 85.7H29.9V84.7H28V85.7ZM33.6 85.7H37.3V84.7H33.6V85.7ZM41 85.7H44.7V84.7H41V85.7ZM48.5 85.7H52.2V84.7H48.5V85.7Z"
        fill={stroke}
      />
      <path
        d="M85.4996 98.1C92.6241 98.1 98.3996 92.3245 98.3996 85.2001C98.3996 78.0756 92.6241 72.3 85.4996 72.3C78.3751 72.3 72.5996 78.0756 72.5996 85.2001C72.5996 92.3245 78.3751 98.1 85.4996 98.1Z"
        fill={stroke}
      />
      <path
        d="M85.5008 104.9C96.3808 104.9 105.201 96.08 105.201 85.2C105.201 74.32 96.3808 65.5 85.5008 65.5C74.6208 65.5 65.8008 74.32 65.8008 85.2C65.8008 96.08 74.6208 104.9 85.5008 104.9Z"
        stroke={stroke}
        strokeDasharray="4 4"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_1016_8179">
        <rect
          fill="white"
          height="104.5"
          transform="translate(28 34)"
          width="116"
        />
      </clipPath>
    </defs>
  </Base>
)

Integrated.propTypes = BaseProps

export default Integrated
