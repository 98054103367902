import React from "react"
import "bootstrap/dist/css/bootstrap.css"
import Icon from "components/elements/Icon"
import Col from "../../Col"
import { navigate } from "gatsby"

const Schedule = () => {
  const continueBtn = () => {
    navigate("/welcomevisit")
  }

  return (
    <div>
      <Col>
        <button className="col" onClick={continueBtn} type="button">
          <h1>Schedule an appointment</h1>
          <Icon
            className="left"
            fill="transparent"
            fillSecondary="#ffffff"
            icon="Arrow"
            stroke="#ffffff"
            strokeWidth="3"
          />
        </button>
      </Col>
    </div>
  )
}

export default Schedule
