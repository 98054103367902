import React from "react"
import Base, { BaseProps } from "../../base"

const ComprehensiveCare = ({ fill, stroke, ...props }) => (
  <Base
    {...props}
    className="g-comprehensiveCare"
    fill="none"
    height="172"
    viewBox="0 0 172 172"
    width="172"
  >
    <circle cx="86" cy="86" fill={fill} r="86" />
    <path
      d="M59 86C73.9117 86 86 73.9117 86 59C86 44.0883 73.9117 32 59 32C44.0883 32 32 44.0883 32 59C32 73.9117 44.0883 86 59 86Z"
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M59 140C73.9117 140 86 127.912 86 113C86 98.0883 73.9117 86 59 86C44.0883 86 32 98.0883 32 113C32 127.912 44.0883 140 59 140Z"
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M113 86C127.912 86 140 73.9117 140 59C140 44.0883 127.912 32 113 32C98.0883 32 86 44.0883 86 59C86 73.9117 98.0883 86 113 86Z"
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M113 140C127.912 140 140 127.912 140 113C140 98.0883 127.912 86 113 86C98.0883 86 86 98.0883 86 113C86 127.912 98.0883 140 113 140Z"
      stroke={stroke}
      strokeMiterlimit="10"
    />
    <path
      d="M114 58H59V114H114V58Z"
      stroke={stroke}
      strokeDasharray="4 4"
      strokeMiterlimit="10"
    />
  </Base>
)

ComprehensiveCare.propTypes = BaseProps

export default ComprehensiveCare
