import React from "react"
import Base, { BaseProps } from "../../base"

const MedicalSecondary = ({ fill, stroke, ...props }) => (
  <Base
    {...props}
    className="g-medicalSecondary"
    fill="none"
    height="172"
    viewBox="0 0 172 172"
    width="172"
  >
    <circle cx="86" cy="86" fill={fill} r="86" />
    <circle cx="86" cy="86" fill={fill} r="86" />
    <circle cx="86" cy="86" r="65.5" stroke={stroke} strokeDasharray="6 6" />
    <rect
      height="73.7273"
      rx="3.5"
      stroke={stroke}
      width="22.0241"
      x="74.7148"
      y="49.9551"
    />
    <rect
      height="73.7273"
      rx="3.5"
      stroke={stroke}
      transform="rotate(90 122.591 75.8068)"
      width="22.0241"
      x="122.591"
      y="75.8068"
    />
  </Base>
)

MedicalSecondary.propTypes = BaseProps

export default MedicalSecondary
